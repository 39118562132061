import { useEffect } from "react"
import { useOverview } from "../../stores/useOverview"
import { useSize } from "../../stores/useSize"

import { OverviewDesktop } from "./OverviewDesktop"
import { OverviewMobile } from "./OverviewMobile"

export function Overview() {
  /*
   * properties
   */

  // const { isMobile } = useSize();
  // const { unsetActiveSite } = useOverview();
  const isMobile = useSize((state) => state.isMobile)
  const unsetActiveSite = useOverview((state) => state.unsetActiveSite)

  /*
   * hooks
   */

  useEffect(() => {
    unsetActiveSite()
  }, [])

  /*
   * visuals
   */

  return <>{isMobile ? <OverviewMobile /> : <OverviewDesktop />}</>
}

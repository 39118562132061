import style from './Programmes.module.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Pagination } from 'swiper/modules';
import { useMemo, useState } from 'react';
import { ProgrammePanel } from './ProgrammePanel';
import { RoundButton } from '../../../components/RoundButton';
import { ContentTitle } from '../shared/ContentTitle';
import { useSettings } from '../../../../stores/useSettings';

export function Programmes({ content }) {
  /*
   * properties
   */

  const getLabel = useSettings((state) => state.getLabel)
  const [swiper, setSwiper] = useState();

  /*
   * hooks
   */

  // const data = useMemo(() => {
  //   // console.log('Programmes :: set data');
  //   // console.log('content:');
  //   // console.log(content);

  //   const panels = i18next.t('programme', { returnObjects: true });

  //   // console.log('panels:');
  //   // console.log(panels);

  //   return panels.filter((item) => content.includes(item.id));
  // }, [content]);

  /*
   * visuals
   */

  return (
    <div className={style.container}>
      <ContentTitle
        // label={t('modal.programmesTitle')}
        // emoticon={t('modal.programmesEmoticon')}

        label={getLabel("workExperiencePrograms", "title").toUpperCase()}
        emoticon={getLabel("workExperiencePrograms", "emoji", false)}
      />
      <div className={style.wrapper}>
        <Swiper
          onSwiper={setSwiper}
          className={style.swiper}
          slidesPerView={1}
          slidesPerGroup={1}
          spaceBetween={0}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            1000: {
              slidesPerView: Math.min(content.length, 2),
              slidesPerGroup: Math.min(content.length, 2),
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: Math.min(content.length, 3),
              slidesPerGroup: Math.min(content.length, 3),
              spaceBetween: 20,
            },
            1800: {
              slidesPerView: Math.min(content.length, 4),
              slidesPerGroup: Math.min(content.length, 4),
              spaceBetween: 20,
            },
          }}
          modules={[Grid, Pagination]}
        >
          {content.map((value, index) => (
            <SwiperSlide key={index} className={style.swiperSlide}>
              <ProgrammePanel data={value} />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className={style.buttonWrapper}>
          <RoundButton
            icon="arrow-back"
            color="minimal"
            onClick={() => {
              swiper.slidePrev();
            }}
          />
          <RoundButton
            icon="arrow-next"
            color="minimal"
            onClick={() => {
              swiper.slideNext();
            }}
          />
        </div>
      </div>
    </div>
  );
}

import style from "./Modal.module.css"

import { useEffect, useMemo, useRef } from "react"
import { useLocation } from "react-router-dom"
import gsap from "gsap"

import { useSite } from "../../stores/useSite"
import { useModal } from "../../stores/useModal"
import { useSize } from "../../stores/useSize"
import { useTransit } from "../../stores/useTransit"
import { useCameras } from "../../stores/useCameras"
import { ModalMenu } from "./menu/ModalMenu"
import { Courses } from "./content/course/Courses"
import { Spotlights } from "./content/spotlight/Spotlights"
import { Jobs } from "./content/jobs/Jobs"
import { Employability } from "./content/employability/Employability"
import { VR } from "./content/vr/VR"
import { Video } from "./content//video/Video"
import { RoundButton } from "../components/RoundButton"
import { useHotspot } from "../../stores/useHotspot"
import { MainSubMenu } from "../components/MainSubMenu"
import { Programmes } from "./content/programme/Programmes"
import { useSettings } from "../../stores/useSettings"

export function Modal() {
  /*
   * properties
   */

  const settings = useSettings((state) => state.sections)

  // const { site } = useSite()
  // const { shown, hideModal } = useModal()
  // const { isMobile } = useSize()
  // const { getHotspot, activeHotspot, unsetActiveHotspot } = useHotspot()

  const location = useLocation()

  const site = useSite((state) => state.site)
  const shown = useModal((state) => state.shown)
  const hideModal = useModal((state) => state.hideModal)
  const isMobile = useSize((state) => state.isMobile)
  const getHotspot = useHotspot((state) => state.getHotspot)
  const activeHotspot = useHotspot((state) => state.activeHotspot)
  const unsetActiveHotspot = useHotspot((state) => state.unsetActiveHotspot)

  const container = useRef()
  const contentWrapper = useRef()

  /*
   * hooks
   */

  //TEST
  //hide in case the meny wasn't already hidden (when navigating with prev/next buttons in browser)
  useEffect(() => {
    if (shown) {
      const modalElement = container.current

      const focusableElements = Array.from(
        modalElement.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        ),
      )
      const firstElement = focusableElements[0]
      const lastElement = focusableElements[focusableElements.length - 1]

      const handleTabKeyPress = (event) => {
        if (event.key === "Tab") {
          if (!focusableElements.includes(document.activeElement)) {
            lastElement.focus()
          }

          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault()
            lastElement.focus()
          } else if (
            !event.shiftKey &&
            document.activeElement === lastElement
          ) {
            event.preventDefault()
            firstElement.focus()
          }
        }
      }

      const handleEscapeKeyPress = (event) => {
        if (event.key === "Escape") {
          hideModal()
        }
      }

      window.addEventListener("keydown", handleTabKeyPress)
      window.addEventListener("keydown", handleEscapeKeyPress)

      return () => {
        window.removeEventListener("keydown", handleTabKeyPress)
        window.removeEventListener("keydown", handleEscapeKeyPress)
      }
    }
  }, [shown])

  // useEffect(() => {
  //   console.log('SHOW/HIDE MODAL');
  //   console.log('shown', shown);
  // }, [shown]);

  //hide in case the meny wasn't already hidden (when navigating with prev/next buttons in browser)
  useEffect(() => {
    // console.log(container);
    gsap.set(container.current, { autoAlpha: 0 })
  }, [location])

  const hotspot = useMemo(() => {
    // console.log('*** getting hotspot');
    // console.log(' - activeHotspot: ' + activeHotspot);
    // const temp = getHotspot(activeHotspot, site)
    // console.log(temp);
    return getHotspot(activeHotspot, site)
  }, [activeHotspot, site])

  /*
   * hide
   */

  useEffect(() => {
    gsap.killTweensOf(container.current)
    if (shown) {
      // show menu when the transitions have finished
      gsap.set(container.current, { autoAlpha: 1, scale: 1 })
      gsap.from(container.current, {
        autoAlpha: 0,
        duration: 0.5,
        ease: "power2.out",
      })
    } else {
      // hide menu when the out transition starts
      gsap.to(container.current, {
        autoAlpha: 0,
        duration: 0.3,
        ease: "power2.in",
      })
    }
  }, [shown])

  const closeHandler = () => {
    // console.log('useHotspot.getState().activeHotspot');
    // console.log(useHotspot.getState().activeHotspot);
    // console.log('useSize.getState().isMobile');
    // console.log(useSize.getState().isMobile);
    // if (useHotspot.getState().activeHotspot && useSize.getState().isMobile) {
    //   unsetActiveHotspot();
    // } else {
    //   hideModal();
    // }

    hideModal()
  }

  const backHandler = () => {
    unsetActiveHotspot()
  }

  useEffect(() => {
    if (contentWrapper && contentWrapper.current) {
      if (isMobile) {
        gsap.set(contentWrapper.current, { opacity: 0, x: 0 })
      } else {
        gsap.set(contentWrapper.current, { opacity: 0, x: 20 })
      }
      gsap.to(contentWrapper.current, { opacity: 1, x: 0 })
    }
  }, [activeHotspot])

  /*
   * visuals
   */

  return (
    <>
      <div
        ref={container}
        // aria-hidden={!shown}
        // aria-modal={shown}
        // tabIndex="-1"
        className={`${style.container} ${
          isMobile && activeHotspot && style.big
        }`}
      >
        <div className={style.wrapper}>
          {!(isMobile && activeHotspot) && (
            <div className={style.menu}>
              {isMobile && (
                <h1 className={style.title}>
                  {/* {t(`sections.${site}`)} */}
                  {settings[site]}
                </h1>
              )}
              <ModalMenu />
            </div>
          )}
          {!(isMobile && !activeHotspot) && (
            <div className={style.content}>
              {isMobile && (
                <div className={style.top}>
                  <RoundButton
                    onClick={backHandler}
                    icon={"arrow-back"}
                    color="white"
                  />
                  <div className={style.level}>L{hotspot && hotspot.level}</div>
                  <h1 className={style.title}>{hotspot && hotspot.name}</h1>
                </div>
              )}

              <div ref={contentWrapper} className={style.contentWrapper}>
                {/* <h1>{hotspot.hotspotType[0]._type}</h1> */}

                {hotspot &&
                  hotspot.hotspotType[0]._type === "industryIntroduction" && (
                    <Video content={hotspot.hotspotType[0].videos} />
                  )}

                {hotspot &&
                  hotspot.hotspotType[0]._type === "employabilityCards" && (
                    <Employability content={hotspot.hotspotType[0].cards} />
                  )}

                {hotspot &&
                  hotspot.hotspotType[0]._type === "workExperienceSprints" && (
                    <Jobs content={hotspot.hotspotType[0].sprints} />
                  )}

                {hotspot &&
                  hotspot.hotspotType[0]._type === "workExperiencePrograms" && (
                    <Programmes content={hotspot.hotspotType[0].programs} />
                  )}

                {hotspot &&
                  hotspot.hotspotType[0]._type === "collegePrograms" && (
                    <Spotlights content={hotspot.hotspotType[0].programs} />
                  )}

                {/* {hotspot && hotspot.type === 'video' && <Video content={hotspot.content} />}
                {hotspot && hotspot.type === 'video' && <Spotlights content={hotspot.content} />}
                {hotspot && hotspot.type === 'course' && <Courses content={hotspot.content} />}
                {hotspot && hotspot.type === 'course' && <Programmes content={hotspot.content} />}
                {hotspot && hotspot.type === 'employability' && <Employability content={hotspot.content} />}
                {hotspot && hotspot.type === 'stories' && <Jobs content={hotspot.content} />}
                {hotspot && hotspot.type === 'vr' && <VR content={hotspot.content} />} */}
              </div>
            </div>
          )}
          <div className={style.closeButton}>
            <RoundButton
              onClick={closeHandler}
              icon={isMobile && activeHotspot ? "close" : "close"}
              color="white"
            />
          </div>
          {isMobile && !activeHotspot && (
            <div className={style.submenuWrapper}>
              <MainSubMenu />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

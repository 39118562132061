import { ContentTitle } from "../shared/ContentTitle"
import style from "./Video.module.css"

import { VideoPanel } from "./VideoPanel"
import { NextVideos } from "./NextVideos"
import { useSize } from "../../../../stores/useSize"
import { useEffect, useState } from "react"
import { useSettings } from "../../../../stores/useSettings"

export function Video({ content }) {
  /*
   * properties
   */

  const getLabel = useSettings((state) => state.getLabel)
  // const { isMobile } = useSize()
  const isMobile = useSize((state) => state.isMobile)
  const [activeId, setActiveId] = useState()

  // const panels = i18next.t("video", { returnObjects: true })

  

  /*
   * hooks
   */

  useEffect(() => {
    console.log("Video :: onMount")
    console.log("content:")
    console.log(content)

    setActiveId(content[0]._key)
  }, [content])

  const setNextVideo = (id) => {
    console.log("setNextVideo, id: " + id)
    setActiveId(id)
  }

  /*
   * visuals
   */

  return (
    <div className={style.container}>
      <ContentTitle
        // label={t("modal.videoTitle")}
        // emoticon={t("modal.videoEmoticon")}

        label={getLabel("industryIntroduction", "title").toUpperCase()}
        emoticon={getLabel("industryIntroduction", "emoji", false)}
      />
      <div className={style.wrapper}>
        {isMobile && (
          <>
            {content.map(
              (value, index) =>
                value._key === activeId && (
                  <VideoPanel key={index} data={value} />
                ),
            )}
            {content.map(
              (value, index) =>
                value._key !== activeId && (
                  <VideoPanel key={index} data={value} />
                ),
            )}
          </>
        )}

        {!isMobile && (
          <>
            {content.map(
              (value, index) =>
                value._key === activeId && (
                  <VideoPanel key={index} data={value} />
                ),
            )}
            <NextVideos
              id={activeId}
              setNextVideo={setNextVideo}
              panels={content}
            />
            {/* </> */}
          </>
        )}
      </div>
    </div>
  )
}

import { create } from 'zustand';

export const useDepthOfField = create((set) => ({
  focusDistance: 1, // the focus distance to be used in the DepthOfField postprocessing (set on Site level)
  focalLength: 1, // the focal length to be used in the DepthOfField postprocessing (set on Site level)
  showBokeh: true, // show or hide the DepthOfField postprocessing

  // set the DepthOfField postprocessing properties for the active Site
  setDepthOfField: (focusDistance, focalLength) => set({ focusDistance, focalLength }),

  // show/hide the DepthOfField postprocessing
  setBokeh: (showBokeh) => set({ showBokeh }),
}));

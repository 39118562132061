import style from './MainLoader.module.css';

import { useProgress } from '@react-three/drei';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export function MainLoader() {
  const ref = useRef();
  const { active, progress } = useProgress();

  useEffect(() => {
    // console.log('MainLoader :: active has changed');
    // console.log(active);

    gsap.killTweensOf(ref.current);
    if (active) {
      //show
      gsap.set(ref.current, { width: 100 });
      gsap.to(ref.current, { autoAlpha: 1, width: 110, duration: 0.15, ease: 'power4.out' });
    } else {
      //hide
      gsap.to(ref.current, { autoAlpha: 1 });
      gsap.to(ref.current, { autoAlpha: 0, width: 40, duration: 0.75, ease: 'power4.in' });
    }
  }, [active]);

  return (
    <div className={style.mainLoader}>
      <div className={style.bar} ref={ref}>
        <div className={style.inner} style={{ width: `${Math.round(progress)}%` }} />
      </div>
    </div>
  );
}

import { useEffect, useMemo, useState } from "react"
import { useCameras } from "../../stores/useCameras"
import { Site } from "./Site"
import { useTexture, useVideoTexture } from "@react-three/drei"

export function Business({ ktx2Loader, dracoLoader }) {
  /*
   * properties
   */

  const [model, setModel] = useState()

  // const { cameraStatus } = useCameras()
  const cameraStatus = useCameras((state) => state.cameraStatus)

  // textures and videos
  // const test_screen_bars = useTexture('/textures/test_screen_bars.jpg');
  // const video1 = useVideoTexture('/video/glitch.mp4');
  const uv_test = useTexture("/textures/uv_test.jpg")

  // link textures and/or video's to model nodes
  const screenTextures = {
    Screen_Info_Point_Lvl01: uv_test,
    //Hotspot_Screen_Lvl01: test_screen_bars,
  }

  /*
   * init: run Site specific code after the modal has been loaded
   */

  const init = (src, animations) => {
    // console.log('Business :: init');
    // console.log(src);
    setModel(src)
    const action = animations.actions["Animation"]
    action.play()
  }

  // show menu when the in transition starts
  useEffect(() => {
    // console.log('cameraStatus:');
    // console.log(cameraStatus);

    if (cameraStatus === "intro") {
      model.nodes.Windows_Cover.visible = false
      model.nodes.Props_Cover.visible = false
      model.nodes.Building_Cover.visible = false
    }
    // if (cameraStatus === 'intro' && site !== 'home') {
    //   //console.log('LevelMenu :: show')
    //   gsap.killTweensOf(nav.current);
    //   gsap.set(nav.current, { y: 0, opacity: 1, scale: 1 });
    //   gsap.from(nav.current, { delay: .45, y: 30, autoAlpha: 0, duration: 0.7, ease: 'power4.out' });
    // }
  }, [cameraStatus])

  /*
   * visuals
   */

  return (
    <group>
      <Site
        name="business"
        cameraOrder={[2, 3, 1, 0]}
        cameraScale={0.2}
        focusDistance={0.04}
        focusLength={0.04}
        init={init}
        // url={'/models/business/03_Business_001.glb'}
        // url={'/models/business/resize.glb'}

        url={"/models/business/resize-ktx-ETC1S-draco.glb"}
        // url={'/models/business/03_Business_012_Screen_UVs_Updated.glb'}

        // url={'/models/business/resize-ktx-ETC1S.glb'}
        // url={'/models/business/resize-ktx-UASTC-draco.glb'}
        // url={'/models/business/resize-ktx-UASTC.glb'}

        // url={'/models/business/_bak/resize-ktx-ETC1S-draco.glb'}

        screenTextures={screenTextures}
        ktx2Loader={ktx2Loader}
        dracoLoader={dracoLoader}
      />
    </group>
  )
}

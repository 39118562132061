import { useEffect, useRef } from "react"
import { useOverview } from "../../stores/useOverview"
import { useSize } from "../../stores/useSize"
import style from "./OverviewMobile.module.css"
import gsap from "gsap"
import { useTransit } from "../../stores/useTransit"
import { useSettings } from "../../stores/useSettings"
import { useHotspot } from "../../stores/useHotspot"

export function OverviewMobile() {
  /*
   * properties
   */

  const container = useRef()

  // const settings = useSettings((state) => state.sections)
  // const { activeSite, sites, setActiveSite, unsetActiveSite } = useOverview()
  // const { startTransitionOut } = useTransit()
  const getLabel = useSettings((state) => state.getLabel)
  // const { sanityHotspots } = useHotspot()

  const activeSite = useOverview((state) => state.activeSite)
  const sites = useOverview((state) => state.sites)
  const setActiveSite = useOverview((state) => state.setActiveSite)
  const unsetActiveSite = useOverview((state) => state.unsetActiveSite)

  const sanityHotspots = useHotspot((state) => state.sanityHotspots)

  /*
   * hooks
   */

  useEffect(() => {
    unsetActiveSite()
  }, [])

  useEffect(() => {
    const bottom = !activeSite ? 0 : -174
    gsap.to(container.current, { bottom, duration: 0.3, ease: "power1.inOut" })
  }, [activeSite])

  /*
   * interaction
   */

  // on discover industries click
  const discoverButtonClick = (index, data) => {
    unsetActiveSite()
  }

  // on siteButton click
  const siteButtonClick = (index, data) => {
    // console.log(index);
    // console.log(data);
    setActiveSite(data)
    // startTransitionOut(data.route);
  }

  /*
   * visuals
   */
  return (
    <>
      <div ref={container} className={style.container}>
        <button className={style.discover} onClick={discoverButtonClick}>
          {getLabel("ui-terms", "discoverIndustries")}
        </button>
        <div className={style.grid}>
          {sites.map((value, index) => (
            // <button
            //   key={index}
            //   className={`${style.siteButton} ${style.disabled}`}
            //   onClick={() => siteButtonClick(index, value)}
            // >
            //   <img
            //     className={style.icon}
            //     src={`/images/emoji/${value.id}.png`}
            //   />
            //   {
            //     sanityHotspots.industries.find(
            //       (hotspot) => hotspot.uid === value.id,
            //     ).industryName
            //   }
            // </button>
            <Button
              key={index}
              index={index}
              data={value}
              sanityHotspots={sanityHotspots}
              clickHandler={siteButtonClick}
            />
          ))}
        </div>
      </div>
    </>
  )
}

function Button({ data, index, sanityHotspots, clickHandler }) {
  const industry = sanityHotspots.industries.find(
    (hotspot) => hotspot.uid === data.id,
  )

  const label = industry.industryName
  const visible = industry.visibility

  console.log(label)
  console.log(visible)

  return (
    <>
      <button
        key={index}
        disabled={!visible}
        className={`${style.siteButton} ${!visible ? style.disabled : null}`}
        onClick={() => visible && clickHandler(index, data)}
      >
        <img className={style.icon} src={`/images/emoji/${data.id}.png`} />
        {
          sanityHotspots.industries.find((hotspot) => hotspot.uid === data.id)
            .industryName
        }
      </button>
    </>
  )
}

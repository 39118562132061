import { useEffect } from "react"
import { useSize } from "../../stores/useSize"

export function SizeController() {
  /*
   * properties
   */

  // const { setMobile } = useSize();
  const setMobile = useSize((state) => state.setMobile)

  /*
   * resize functionality
   */

  useEffect(() => {
    window.addEventListener("resize", resizeHandler)
    resizeHandler()
  }, [])

  const resizeHandler = () => {
    setMobile(window.innerWidth <= 800)
  }
}

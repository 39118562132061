import { useControls } from 'leva';
import { Perf } from 'r3f-perf';

export function Staging() {
  /*
   * properties
   */

  const showPerf = true;

  /*
   * visuals
   */

  return (
    <>
      <color attach="background" args={['#161821']} />
      {/* <color attach="background" args={['#19224b']} /> */}
      {/* {showPerf && <Perf position="top-left" />} */}
    </>
  );
}

import { useGuide } from "../../stores/useGuide"
import { useSize } from "../../stores/useSize"
import { RoundButton } from "../components/RoundButton"
import { Desktop } from "./Desktop"
import style from "./Guide.module.css"
import { Mobile } from "./Mobile"

export function Guide() {
  const isMobile = useSize((state) => state.isMobile)
  const shown = useGuide((state) => state.shown)
  const hideGuide = useGuide((state) => state.hideGuide)

  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */
  return (
    <>
      {shown && (
        <div className={style.container}>
          {isMobile && <Mobile />}
          {!isMobile && <Desktop />}
          <div className={style.buttonContainer}>
            <RoundButton
              color="transparent-dark"
              icon="close"
              onClick={hideGuide}
            />
          </div>
        </div>
      )}
    </>
  )
}

import { create } from 'zustand';

export const useCameras = create((set) => ({
  cameras: [], // the 4 cameras (intro + level 1/2/3) in the active Site
  cameraStatus: '', //  the camera status: 'intro', 'level', 'transition'
  cameraScale: 1, // the scale of the camera moments (set on Site level)

  // set the properties for the active Site
  setCameras: (cameras, cameraScale) => set({ cameras, cameraScale }),

  // start transitioning the camera to a new position
  startCameraTransition: () => set({ cameraStatus: 'transition' }),

  // set the camera status after transitioning the camera to a new position
  endCameraTransition: (status) => set({ cameraStatus: status }),
}));

export const SUPPORTED_LOCALES = [
  {
    code: 'en',
    title: 'English',
    label: '🇺🇸 EN',
    default: true,
  },
  {
    code: 'es',
    label: '🇪🇸 ES',
    title: 'Española',
  },
]

export const DEFAULT_LOCALE = SUPPORTED_LOCALES.find((locale) => locale.default)

export const SHORTHAND_LOCALES = SUPPORTED_LOCALES.map((locale) => locale.code)
import imageUrlBuilder from "@sanity/image-url"
import { client } from "./sanity"

export const getImage = (node, width, height) => {
    // console.log("getImage")
    // console.log(node)

  const builder = imageUrlBuilder(client)
  const quality = 77
  const imageUrl = builder
    .image(node.image.asset)
    .auto("format") // auto return Webp if browser supports it
    .size(width, height)
    .quality(quality)
    .url()

  // console.log("imageUrl")
  // console.log(imageUrl)

  return imageUrl
}

export const getPosterImage = (node) => {
  return getImage(node, 640, 360)
}

export const getLogo = (node) => {
  return getImage(node, 200)
}
import { useEffect, useRef } from "react"
import { useOverview } from "../../stores/useOverview"
import style from "./OverviewDesktop.module.css"
import gsap from "gsap"
import { useTransit } from "../../stores/useTransit"
import { useSettings } from "../../stores/useSettings"
import { useHotspot } from "../../stores/useHotspot"

export function OverviewDesktop() {
  /*
   * properties
   */

  const nav = useRef()
  const highlight = useRef()

  const getLabel = useSettings((state) => state.getLabel)

  const sanityHotspots = useHotspot((state) => state.sanityHotspots)
  const activeSite = useOverview((state) => state.activeSite)
  const sites = useOverview((state) => state.sites)
  const setActiveSite = useOverview((state) => state.setActiveSite)
  const unsetActiveSite = useOverview((state) => state.unsetActiveSite)
  const startTransitionOut = useTransit((state) => state.startTransitionOut)

  /*
   * hooks
   */

  useEffect(() => {
    unsetActiveSite()
  }, [])

  useEffect(() => {
    if (activeSite) {
      var index = sites
        .map(function (x) {
          return x.id
        })
        .indexOf(activeSite.id)
      setHighlight(index)
    }
  }, [activeSite])

  /*
   * interaction
   */

  // on mouse over
  const overHandler = (index, data) => {
    // console.log(index);
    // console.log(data);
    setActiveSite(data)
  }

  // on mouse out
  const outHandler = () => {
    unsetActiveSite()
  }

  // on click
  const clickHandler = (index, data) => {
    // console.log('HotspotMenu :: clickHandler');
    // console.log(index, hotspots[index].id);
    startTransitionOut(data.route)
  }

  /*
   * set highlight
   */

  const setHighlight = (index, animate = true) => {
    //console.log('HotspotMenu :: setHighlight');
    //console.log(index);
    const element = nav.current.children[index + 2]

    if (element) {
      const duration = animate ? 0.35 : 0

      gsap.killTweensOf(highlight.current)
      gsap.to(highlight.current, {
        // x: element.offsetLeft - nav.current.offsetLeft - 6, // padding + border
        x: element.offsetLeft - 6, // padding + border
        width: element.offsetWidth,
        duration,
        ease: "power4.inOut",
      })
    }
  }

  /*
   * visuals
   */
  return (
    <>
      <div className={style.container}>
        <nav ref={nav} className={style.nav}>
          <div
            ref={highlight}
            className={`${style.highlight} ${activeSite && style.active}`}
          />
          <div className={style.title}>
            {getLabel("ui-terms", "industries").toUpperCase()}
          </div>
          {sites.map((value, index) => (
            <Button
              key={index}
              data={value}
              index={index}
              activeSite={activeSite}
              sanityHotspots={sanityHotspots}
              overHandler={overHandler}
              outHandler={outHandler}
              clickHandler={clickHandler}
            />
          ))}
        </nav>
      </div>
    </>
  )
}

function Button({
  data,
  index,
  activeSite,
  sanityHotspots,
  overHandler,
  outHandler,
  clickHandler,
}) {
  const industry = sanityHotspots.industries.find(
    (hotspot) => hotspot.uid === data.id,
  )

  const label = industry.industryName
  const visible = industry.visibility

  return (
    <>
      {visible && (
        <button
          className={`${style.button} ${
            activeSite && activeSite.id === data.id && style.active
          }`}
          onFocus={() => overHandler(index, data)}
          onMouseOver={() => overHandler(index, data)}
          onBlur={outHandler}
          onMouseOut={outHandler}
          onClick={() => clickHandler(index, data)}
        >
          {label}
        </button>
      )}
      {!visible && (
        <button disabled className={`${style.button} ${style.disabled}`}>
          {label}
        </button>
      )}
    </>
  )
}

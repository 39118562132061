import { extend, useFrame, useLoader, useThree } from "@react-three/fiber"
import { useRef } from "react"
import { Water } from "three/examples/jsm/objects/Water.js"
import * as THREE from "three"
import { useMemo } from "react"
import { useControls } from "leva"
import { useEffect } from "react"
import { useTexture } from "@react-three/drei"

extend({ Water: Water })

export function Ocean() {
  //console.log('Ocean :: render');

  const ref = useRef()
  const gl = useThree((state) => state.gl)

  const waterNormals = useTexture("/textures/waternormals.jpg")
  waterNormals.wrapS = waterNormals.wrapT = THREE.RepeatWrapping

  const geom = useMemo(() => new THREE.PlaneGeometry(50, 50), [])
  // const geom = useMemo(() => new THREE.PlaneGeometry(10, 10), []);

  const config = useMemo(
    () => ({
      sunDirection: new THREE.Vector3(),
      // sunDirection: new THREE.Vector3(0, 1, 0),
      sunColor: "#f265d6",
      // sunColor: "#a2cdfe",
      // waterColor: "#a2cdfe",
      waterColor: "#0064b5",
      textureWidth: 1024,
      textureHeight: 1024,
      // distortionScale: 0.7,
      distortionScale: 3,
      waterNormals,
      format: gl.encoding,
    }),
    [waterNormals],
  )

  // const params = useControls('Water', {
  //   sunDirection: {
  //     value: { x: config.sunDirection.x, y: config.sunDirection.y, z: config.sunDirection.z },
  //     min: -10,
  //     max: 10,
  //     step: 0.1,
  //   },
  //   sunColor: config.sunColor,
  //   waterColor: config.waterColor,
  //   distortionScale: { value: config.distortionScale, min: 0, max: 100, step: 0.1 },
  //   size: { value: 4.5, min: 0, max: 10, step: 0.1 },
  // });

  // useEffect(() => {
  //   const uniforms = ref.current.material.uniforms;
  //   uniforms.sunDirection.value.copy(params.sunDirection);
  //   uniforms.sunColor.value.set(params.sunColor);
  //   uniforms.waterColor.value.set(params.waterColor);
  //   uniforms.distortionScale.value = params.distortionScale;
  //   uniforms.size.value = params.size;
  // }, [params]);

  useEffect(() => {
    const uniforms = ref.current.material.uniforms
    uniforms.sunDirection.value.copy(config.sunDirection)
    uniforms.sunColor.value.set(config.sunColor)
    uniforms.waterColor.value.set(config.waterColor)
    uniforms.distortionScale.value = config.distortionScale
    uniforms.size.value = 2.0
    // uniforms.size.value = 4.5
  }, [config])

  useFrame((state, delta) => {
    ref.current.material.uniforms.time.value += delta * 0.08
    // ref.current.material.uniforms.time.value += delta * 0.1
  })

  return (
    <water
      ref={ref}
      args={[geom, config]}
      position={[0, -0.1, 0]}
      rotation={[-1.570796, 0, 0]}
    />
  )
}

import { useEffect } from "react"
import { useSite } from "../../stores/useSite"
import { Site } from "./Site"
import { useTexture, useVideoTexture } from "@react-three/drei"

export function Healthcare({ ktx2Loader, dracoLoader }) {
  /*
   * properties
   */

  // textures and videos
  const test_screen_bars = useTexture("/textures/test_screen_bars.jpg")
  const test_screen_circle = useTexture("/textures/test_screen_circle.jpg")
  const video1 = useVideoTexture("/video/glitch.mp4")
  const video2 = useVideoTexture("/video/bananas.mp4")

  // link textures and/or video's to model nodes
  const screenTextures = {
    Hotspot_Screen_Info_Totem: video1,
    Hotspot_Screen_Lvl01: test_screen_bars,
    Screen_Lvl02_Large: test_screen_bars, //SOMETHING IS WRONG
    Hotspot_Screen_Lvl02_01: test_screen_bars,
    Hotspot_Screen_Lvl02_02: test_screen_circle,
    Hotspot_Screen_Lvl03: video2,
  }

  /*
   * init: run Site specific code after the modal has been loaded
   */

  const init = (model, animations) => {
    // console.log('Healthcare :: init');

    // console.log('model');
    // console.log(model);

    // console.log('animations');
    // console.log(animations);

    for (const name of animations.names) {
      // console.log(name);
      const action = animations.actions[name]
      // console.log(action);
      action.play()
    }
  }

  /*
   * visuals
   */

  return (
    <group>
      <Site
        name="healthcare"
        cameraOrder={[1, 2, 3, 0]}
        cameraScale={1}
        focusDistance={0.13}
        focusLength={0.12}
        init={init}
        // url={'/models/healthcare/SP_Healthcare_Updates_Test.glb'}
        // url={'/models/healthcare/resize.glb'}
        // url={'/models/healthcare/ktx-ETC1S.glb'}
        // url={'/models/healthcare/ktx-ETC1S-draco.glb'}

        // url={'/models/healthcare/resize-ktx-ETC1S.glb'}
        // url={'/models/healthcare/resize-ktx-UASTC-draco.glb'}
        url={"/models/healthcare/SP_Healthcare_Compression_Test_02.glb"}
        screenTextures={screenTextures}
        ktx2Loader={ktx2Loader}
        dracoLoader={dracoLoader}
      />
    </group>
  )
}

import { useEffect } from "react"
import { useMouse } from "../../stores/useMouse"

export function MouseController() {
  /*
   * properties
   */
  // const { setMousePosition, calculateSlowMousePosition, setMouseMaximums, initMousePosition } = useMouse();
  const setMousePosition = useMouse((state) => state.setMousePosition)
  const calculateSlowMousePosition = useMouse(
    (state) => state.calculateSlowMousePosition,
  )
  const setMouseMaximums = useMouse((state) => state.setMouseMaximums)
  const initMousePosition = useMouse((state) => state.initMousePosition)

  /*
   * hooks
   */

  // start
  useEffect(() => {
    window.addEventListener("resize", resizeHandler)
    resizeHandler()
    window.addEventListener("mousemove", handleMouseMove)

    const intervalId = setInterval(loop, 1000 / 60)

    return () => {
      clearInterval(intervalId)
      window.removeEventListener("resize", resizeHandler)
      window.removeEventListener("mousemove", handleMouseMove)
    }
  }, [])

  // resize
  const resizeHandler = () => {
    const xMax = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0,
    )
    const yMax = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0,
    )

    setMouseMaximums(xMax, yMax)

    if (!useMouse.getState().inited && xMax > 0 && yMax > 0) {
      setMousePosition(xMax * 0.5, yMax * 0.5)
    }
  }

  // mouse move
  const handleMouseMove = (event) => {
    const x = event.clientX
    const y = event.clientY

    initMousePosition()
    setMousePosition(x, y)
  }

  const loop = () => {
    if (useMouse.getState().inited) {
      calculateSlowMousePosition()
    }
  }
}

import style from "./Transit.module.css"

import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import gsap from "gsap"

import { useTransit } from "../../stores/useTransit"
import { useSite } from "../../stores/useSite"
import {
  getLocaledPath,
  getLocalizedPath,
  useLocalizedPath,
} from "../../utils/localization"
import { DEFAULT_LOCALE } from "../../../config"
import { useIsomorphicLayoutEffect } from "../../utils/useIsomorphicLayoutEffect"
import { useLocale } from "../../stores/useLocale"

export function Transit() {
  const { locale } = useLocalizedPath()
  const { currentLocale, setCurrentLocale } = useLocale()

  // setCurrentLocale(locale)
  /*
   * properties
   */

  const [transition, endTransitionIn, startLoad] = useTransit((state) => [
    state.transition,
    state.endTransitionIn,
    state.startLoad,
  ])
  const site = useSite((state) => state.site)
  const location = useLocation()
  const navigate = useNavigate()

  const background = useRef()

  useIsomorphicLayoutEffect(() => {
    const html = document.querySelector("html")
    html.setAttribute("lang", currentLocale)
  }, [currentLocale])

  /*
   * hooks
   */

  // hide current site and navigate to route when out transition is finished
  useEffect(() => {
    // out transition: hide current site
    if (transition === "out") {
      // console.log('Transition :: transition out');
      // console.log(' - transition: ' + transition);

      gsap.killTweensOf(background.current)
      // gsap.set(background.current, { y: '-100%', borderRadius: 50, backgroundColor: 'rgba(80, 80, 80, 0.6)' });
      gsap.to(background.current, {
        delay: 0.2,
        y: 0,
        borderRadius: 0,
        // backgroundColor: 'rgba(0, 0, 0, 1)',
        onComplete: endTransitionOut,
        duration: 0.65,
        ease: "power2.out",
      })
    }
  }, [transition])

  const endTransitionOut = () => {
    const path = useTransit.getState().route
    const route = getLocaledPath({ path, locale })

    //navigate to the given route

    navigate(route)
  }

  // block view with transition
  useEffect(() => {
    if (locale !== currentLocale) {
      setCurrentLocale(locale)
    }
    // console.log('Transition :: change location');
    // console.log(' - location: ' + location.pathname);

    // set transition state to 'loading'
    // and save route (which isn't the case if site was opened directly to certain route)
    startLoad(location.pathname)

    // show background in case it wasn't alreay shown (when navigation with prev/next buttons in browser)
    // gsap.set(background.current, { y: 0, borderRadius: 0, backgroundColor: 'rgba(0, 0, 0, 1)' });
  }, [location])

  // show new site
  useEffect(() => {
    // in transition: show new site after the new site has been fully loaded
    if (site) {
      // console.log('Transition :: transition in');
      // console.log(' - site: ' + site);

      gsap.killTweensOf(background.current)
      gsap.to(background.current, {
        y: "100%",
        borderRadius: 50,
        // backgroundColor: 'rgba(80, 80, 80, 0.6)',
        onComplete: endTransitionIn,
        duration: 1,
        ease: "power4.in",
      })
    }
  }, [site])

  /*
   * visuals
   */

  return (
    <div className={style.container}>
      <div ref={background} className={style.background}></div>
    </div>
  )
}

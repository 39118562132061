import { create } from "zustand"
import { getIndustries, localize } from "../utils/sanity"

export const useHotspot = create((set, get) => ({
  activeHotspot: null, // the id of the active hotspot
  loadingHotspots: true,
  hasError: false,
  sanityHotspots: {},
  hotspots: {},

  fetchSanityHotspots: async (locale) => {
    try {
      const response = await getIndustries()
      const localizedResponse = await localize(response, locale)
      // const hotspots = await response.json()
      // console.log("-------- useHotspot ::', localizedResponse")
      set({ sanityHotspots: localizedResponse })
      console.log(get().sanityHotspots)
    } catch (err) {
      console.log(err)
      set({ hasError: true })
    } finally {
      set({ loadingHotspots: false })
    }
  },

  fetchHotspots: async () => {
    try {
      const response = await fetch("/data/hotspots.json")
      const hotspots = await response.json()
      set({ hotspots })
    } catch (error) {
      set({ hasError: true })
    } finally {
      set({ loadingHotspots: false })
    }
  },

  // get a specific hotspot by it's id
  getHotspot: (id, site) => {
    // console.log("useHotspot :: getHotspot")

    const hotspots = get().getHotspots(site, 0)
    // console.log({ hotspots })

    if (hotspots) {
      let hotspot = hotspots.find((hotspot) => hotspot.id === id)
      if (hotspot) {
        //TEMP
        // hotspot.type = "stories"
        // hotspot.content = ["test1", "test2", "test3"]

        // console.log("hotspot:")
        // console.log(hotspot)

        return { ...hotspot, site: site }
      }
    }

    // SANITY HOTSPOTS:

    return null

    // HOTSPOT JSON:

    // const hotspots = get().hotspots[site]

    // if (hotspots) {
    //   let hotspot = hotspots.find((hotspot) => hotspot.id === id)

    //   if (hotspot) {
    //     // add site property to the hotspot
    //     return { ...hotspot, site: site }
    //   } else {
    //     return null
    //   }
    // }
    // return null
  },

  // get all the hotspots for a certain Site and level
  // if the level param is omitted, all the hotspots over all levels are provides
  getHotspots: (site, level = 0) => {
    // console.log("useHotspot :: getHotspots")
    // console.log("site: " + site)
    // console.log("level: " + level)

    //TEST
    // site = "Construction"
    // level = 0

    // SANITY HOTSPOTS:

    const hotspots = get().sanityHotspots


    // console.log("hotspots:----")
    // console.log(hotspots)

    if (hotspots && hotspots.industries) {
      // console.log("hotspots.industries")
      // console.log(hotspots.industries)
      const industries = hotspots.industries.find(
        // (industry) => industry.id === site,
        // (industry) => industry.industryName.en === site, //TEMP
        (industry) => industry.uid === site, //TEMP
      )
      // console.log("industries: ")
      // console.log(industries)

      let levels = []
      if (industries) {
        if ((level === 0 || level === 1)) {
          // console.log(1);
          levels = levels.concat(get().addLevel(industries.levelOne.hotspots, 1))
        }
        if (level === 0 || level === 2) {
          // console.log(2);
          levels = levels.concat(get().addLevel(industries.levelTwo.hotspots, 2))
        }
        if (level === 0 || level === 3) {
          // console.log(3);
          levels = levels.concat(
            get().addLevel(industries.levelThree.hotspots, 3),
          )
        }
      }

      // console.log("levels: ")
      // console.log(levels)

      return levels
    }

    return null

    // HOTSPOT JSON:

    // const hotspots = get().hotspots[site];

    // if (hotspots) {
    //   if (level) {
    //     return hotspots.filter((hotspot) => hotspot.level === level);
    //   } else {
    //     return hotspots;
    //   }
    // }

    // // return null when no hotspot data is found
    // return null;
  },

  addLevel: (hotspots, level) => {
    return hotspots.map((hotspot) => {
      hotspot.level = level
      // console.log(hotspot.hotspotType)

      if (!hotspot.hotspotType) {
        hotspot.hotspotType = [{ _type: "empty" }]
      } else {
        hotspot.disabled = ![
          "industryIntroduction",
          "employabilityCards",
          "workExperienceSprints",
          "collegePrograms",
          "workExperiencePrograms",
        ].includes(hotspot.hotspotType[0]._type)
      }
      // hotspot.disabled = false;

      return hotspot
    })
  },

  // set the currently active hotspot (by hotspot id)
  setActiveHotspot: (activeHotspot) => set({ activeHotspot }),

  // reset the currenly active hotspot (by hotspot id)
  unsetActiveHotspot: () => set({ activeHotspot: null }),
}))

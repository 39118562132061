import style from "./Employability.module.css"

import { Swiper, SwiperSlide } from "swiper/react"
import { Grid, Pagination } from "swiper/modules"
import { useEffect, useMemo, useState } from "react"
import { EmployabilityPanelDesktop } from "./EmployabilityPanelDesktop"
import { EmployabilityPanelMobile } from "./EmployabilityPanelMobile"
import { RoundButton } from "../../../components/RoundButton"
import { useSize } from "../../../../stores/useSize"
import { ContentTitle } from "../shared/ContentTitle"
import { useSettings } from "../../../../stores/useSettings"

export function Employability({ content }) {
  /*
   * properties
   */

  const getLabel = useSettings((state) => state.getLabel)
  const [swiper, setSwiper] = useState()
  // const { isMobile } = useSize()
  const isMobile = useSize((state) => state.isMobile)

  /*
   * hooks
   */

  useEffect(() => {
    console.log("Employability :: get content")
    console.log(content)
  }, [content])

  /*
   * visuals
   */

  return (
    <div className={style.container}>
      <ContentTitle
        // label={t("modal.employabilityTitle")}
        // emoticon={t("modal.employabilityEmoticon")}

        label={getLabel("employability", "title").toUpperCase()}
        emoticon={getLabel("employability", "emoji", false)}
      />
      <div className={style.wrapper}>
        {isMobile && (
          <>
            {content.map((value, index) => (
              <EmployabilityPanelMobile key={index} data={value} />
            ))}
          </>
        )}

        {!isMobile && (
          <>
            <Swiper
              onSwiper={setSwiper}
              className={style.swiper}
              slidesPerView={2}
              slidesPerGroup={2}
              // grid={{ rows: 2 }}
              spaceBetween={20}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                1000: {
                  slidesPerView: Math.min(content.length, 3),
                  slidesPerGroup: Math.min(content.length, 3),
                  // grid: { rows: 2 },
                },
                1250: {
                  slidesPerView: Math.min(content.length, 4),
                  slidesPerGroup: Math.min(content.length, 4),
                  // grid: { rows: 2 },
                },
              }}
              // modules={[Grid, Pagination]}
              modules={[Pagination]}
            >
              {content.map((value, index) => (
                <SwiperSlide key={index} className={style.swiperSlide}>
                  <EmployabilityPanelDesktop data={value} />
                </SwiperSlide>
              ))}
            </Swiper>

            <div className={style.buttonWrapper}>
              <RoundButton
                icon="arrow-back"
                color="minimal"
                onClick={() => {
                  swiper.slidePrev()
                }}
              />
              <RoundButton
                icon="arrow-next"
                color="minimal"
                onClick={() => {
                  swiper.slideNext()
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

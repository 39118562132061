import { useResolvedPath } from "react-router-dom"
import {
  DEFAULT_LOCALE,
  SHORTHAND_LOCALES,
  SUPPORTED_LOCALES,
} from "../../config"
import { useTransit } from "../stores/useTransit"

export const useLocalizedPath = () => {
  const { pathname } = useResolvedPath()

  const currentLocale = pathname.split("/")[1]
  let locale = SUPPORTED_LOCALES.find(
    (locale) => locale.code === currentLocale,
  )?.code
  locale = locale ?? DEFAULT_LOCALE.code // fallback to default locale

  const fullUrl = `/${locale}/${pathname}`

  return { pathname, locale, fullUrl }
}

export const getLocalizedPath = (locale) => {
  const p = useTransit.getState().route
  const location = p.split("/").pop()
  const isHomepage = p.split("/").length <= 2

  const { pathname } = useResolvedPath()

  if (isHomepage) {
    return locale === DEFAULT_LOCALE.code ? "/" : `/${locale}`
  }

  return locale === DEFAULT_LOCALE.code
    ? `/${location}`
    : `/${locale}/${location}`
}

const getLocalePrefix = (locale) => {
  const prefix = locale === DEFAULT_LOCALE.code ? "/" : `/${locale}/`

  return { prefix }
}

export const getCurrentLocale = (currentPath) => {
  const currentLocale = SHORTHAND_LOCALES.includes(currentPath.split("/")[1])
    ? currentPath.split("/")[1]
    : DEFAULT_LOCALE.code

  const prefix = getLocalePrefix(currentLocale)

  return { currentLocale, prefix }
}

const getCurrentPath = (path) => {
  const currentPath = path.split("/").pop()
  const isLocaleRoot = SHORTHAND_LOCALES.includes(currentPath)

  return isLocaleRoot ? "/" : currentPath
}

export const getLocaledPath = ({ path, locale }) => {
  let prefix

  if (locale) {
    prefix = getLocalePrefix(locale)
  } else {
    const { currentLocale } = getCurrentLocale(path)
    prefix = getLocalePrefix(currentLocale)
  }

  let p = getCurrentPath(path)

  return `${prefix?.prefix}${p}`

}

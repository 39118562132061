import { Icon } from "../../app2D/components/Icon"
import style from "./MainSubMenu.module.css"
import { useGuide } from "../../stores/useGuide"
import { useSettings } from "../../stores/useSettings"

export function MainSubMenu() {
  const getLabel = useSettings((state) => state.getLabel)
  const showGuide = useGuide((state) => state.showGuide)

  return (
    <>
      <ul className={style.container}>
        <li>
          <a className={style.link} href="#" onClick={showGuide}>
            <div className={style.icon}>
              <Icon svg="info" light />
            </div>
            {getLabel("header", "guide")}
          </a>
        </li>
        <li>
          <a
            className={style.link}
            href="https://partners.springpod.com/about-us"
            target="_blank"
          >
            {getLabel("header", "about")}
          </a>
        </li>
      </ul>
    </>
  )
}

import { Icon } from "../../../components/Icon"
import style from "./EmployabilityPanelDesktop.module.css"
import { getImage } from "../../../../utils/sanityImages"
import Localize from "../../../components/Localize"

export function EmployabilityPanelDesktop({ data }) {
  return (
    <div className={style.container}>
      <img className={style.image} src={getImage(data.thumbnail, 500, 500)} />
      <div className={style.darken} />
      <div className={style.panelTitle}>{data.name}</div>
      <div className={style.hover}>
        <div className={style.title}>{data.name}</div>
        <div>
          <div className={style.body}>{data.body}</div>
          <a href={data.link} target="_blank" className={style.button}>
            <Localize label="employability.cta" />
            <div className={style.icon}>
              <Icon svg="arrow-next" size="small" />
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

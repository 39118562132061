import { create } from 'zustand';

export const useSite = create((set) => ({
  siteNames: ['home', 'construction', 'healthcare', 'manufacturing', 'technology', 'business', 'defense'], // a list with the names of all the available Sites

  nodes: null, // the glTF nodes of the active Site's model
  level: 1, // the currently active level (1/2/3)
  site: '', // the name of currently active Site (see siteNames)

  // set the name of the active Site
  setSite: (site) => set({ site: site, level: 1 }),

  // set the active level
  setLevel: (level) => set({ level: level }),

  // set the glTF nodes of the active Site's model
  setNodes: (nodes) => set({ nodes: nodes }),
}));

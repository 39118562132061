import { create } from 'zustand';

export const useModal = create((set) => ({
  shown: false, // wheter or not the modal is visible

  // show the modal
  showModal: () => set({ shown: true }),

  // hide the modal
  hideModal: () => set({ shown: false }),
}));

import style from "./OverviewLabel.module.css"

import { useTransit } from "../../stores/useTransit"
import { useOverview } from "../../stores/useOverview"
import { useSettings } from "../../stores/useSettings"

export function OverviewLabel() {
  /*
   * properties
   */

  // const settings = useSettings((state) => state.sections)
  // const { activeSite } = useOverview()
  // const { startTransitionOut } = useTransit()

  const activeSite = useOverview((state) => state.activeSite)
  const startTransitionOut = useTransit((state) => state.startTransitionOut)

  /*
   * hooks
   */

  /*
   * interaction methods
   */

  const onClick = () => {
    console.log("SiteHotspot :: clickHandler")
    //console.log('data.id: ' + data.id);
    startTransitionOut(activeSite.route)
  }

  /*
   * visuals
   */

  return (
    <>
      {/* <button disabled={!(activeSite && activeSite.id === data.id)} onClick={onClick} className={style.button}> */}
      <button disabled={!activeSite} onClick={onClick} className={style.button}>
        {/* {activeSite && activeSite.id && settings[activeSite.id]} */}
      </button>
    </>
  )
}

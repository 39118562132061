import style from "./SiteHotspot.module.css"

import { useEffect, useMemo, useRef, useState } from "react"
import { Html } from "@react-three/drei"
import gsap from "gsap"

import { useModal } from "../../stores/useModal"
import { useSite } from "../../stores/useSite"
import { useTransit } from "../../stores/useTransit"
import { useHotspot } from "../../stores/useHotspot"
import { useFrame } from "@react-three/fiber"
import { useMouse } from "../../stores/useMouse"
import { Icon } from "../../app2D/components/Icon"
import { useCameras } from "../../stores/useCameras"

export function SiteHotspot({ mesh, data }) {
  /*
   * properties
   */

  const button = useRef()
  const group = useRef()
  const element = useRef()
  const ring = useRef()
  const circle = useRef()

  const [isOver, setOver] = useState(false)
  const [isActive, setActive] = useState(false)

  const { level, site } = useSite()
  const { transition } = useTransit()
  const { cameraStatus } = useCameras()
  const { slowX, slowY } = useMouse()
  const { activeHotspot, setActiveHotspot } = useHotspot()
  const { showModal } = useModal()

  // useEffect(() => {
  //   console.log(data.level)
  //   console.log(data.hotspotType[0]._type)
  // }, [data])

  /*
   * hooks
   */

  // set position when the mesh has been loaded
  useEffect(() => {
    // set position of the parent group to the position of the source mesh
    group.current.position.copy(mesh.position)
  }, [mesh])

  // store whether the hotspot should be shown after the level and/or transition has been changed
  const isShown = useMemo(() => {
    // show/hide the hotspot
    const isShown =
      data.site === site && data.level === level && transition === "finished"

    if (button && button.current) {
      gsap.killTweensOf(button.current)
      // if (isShown && cameraStatus === 'level') {
      if (
        isShown &&
        cameraStatus !== "transition" &&
        cameraStatus !== "start"
      ) {
        // show component
        const alpha = data.disabled ? 0.5 : 1

        gsap.to(button.current, {
          autoAlpha: alpha,
          duration: 0.2,
          ease: "power4.out",
        })
      } else {
        // hide component
        gsap.to(button.current, {
          autoAlpha: 0,
          duration: 0.2,
          ease: "sine.in",
        })
      }
    }

    return isShown
  }, [level, transition, cameraStatus])

  // set the active state when the activeHotspot has been changed
  // useEffect(() => {
  //   setActive(activeHotspot === data.id)
  // }, [activeHotspot])

  /*
   * interaction methods
   */

  const clickHandler = () => {
    //console.log('SiteHotspot :: clickHandler');
    //console.log('data.id: ' + data.id);
    if (!data.disabled) {
      showModal()
      setActiveHotspot(data.id)
    }
  }

  const overHandler = () => {
    if (!data.disabled) {
      setOver(true)
    }
  }

  const outHandler = () => {
    if (!data.disabled) {
      setOver(false)
    }
  }

  /*
   * follow mouse
   */

  useFrame(() => {
    if (!data.disabled && element && element.current && ring && ring.current) {
      const pos = element.current.getBoundingClientRect()

      var a = slowX - pos.x
      var b = slowY - pos.y

      var dist = Math.sqrt(a * a + b * b)
      let distPct = 1 - dist / 200 + 0.1
      distPct = Math.max(0, Math.min(1, distPct))
      distPct = 0.2 + distPct * 0.8

      const angleRad = Math.atan2(b, a)
      const angleDeg = (angleRad * 180) / Math.PI - 180 * distPct

      ring.current.style.transform = `rotate(${angleDeg}deg)`
      circle.current.style.strokeDashoffset = `calc(190px - (190px * ${distPct}))`
    }
  })

  /*
   * visuals
   */

  return (
    <>
      <group ref={group}>
        <Html ref={element} zIndexRange={[1000, 0]}>
          <button
            // disabled={!isShown}
            ref={button}
            className={`
              ${style.button}
              ${isActive || isOver ? style.isActive : ""}
              ${data.disabled ? style.disabled : ""}
              ${style.bottom}
            `}
            onClick={clickHandler}
            onMouseOver={overHandler}
            onFocus={overHandler}
            onMouseOut={outHandler}
            onBlur={outHandler}
          >
            <div className={style.wrapper}>
              <div ref={ring} className={style.percent}>
                <svg>
                  <circle cx="32" cy="32" r="30" />
                  <circle ref={circle} cx="32" cy="32" r="30" />
                </svg>
              </div>
              <div className={style.arrow}>
                <Icon svg="arrow-next" light={false} size="large" />
              </div>
              <div className={style.icon}>
                {/* <Icon svg={data.type} size="responsive" /> */}
                <Icon svg={data.hotspotType[0]._type} size="responsive" />
              </div>
            </div>
            {/* <div className={style.text}>{data.name}</div>
            <div className={style.hotspot}></div> */}
          </button>
        </Html>
      </group>
    </>
  )
}

import { Site } from "./Site"
import { useTexture, useVideoTexture } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import { useMemo } from "react"
import * as THREE from "three"

export function Technology({ ktx2Loader, dracoLoader }) {
  /*
   * properties
   */

  // textures and videos
  const test_screen_bars = useTexture("/textures/test_screen_bars.jpg")
  const test_screen_circle = useTexture("/textures/test_screen_circle.jpg")
  const video1 = useVideoTexture("/video/glitch.mp4")
  const video2 = useVideoTexture("/video/bananas.mp4")

  // link textures and/or video's to model nodes
  const screenTextures = {
    Hotspot_Screen_Info_Totem: test_screen_bars,
    Hotspot_Screen_Lvl01: video1,
    Hotspot_Screen_Tablet_01: test_screen_bars,
    Hotspot_Screen_Tablet_02: test_screen_bars,
    // Back_Screen_Lvl01: test_screen_bars,
    // Back_Screen_Lvl03: test_screen_bars,
    Screen_PC_C: test_screen_circle,
    Screen_PC_L: test_screen_circle,
    Screen_PC_R: test_screen_circle,
    Hotspot_Screen_Lvl03: video2,
  }

  const emissiveMaterial = useMemo(() => {
    return new EmissiveMaterial()
  }, [])

  /*
   * init: run Site specific code after the modal has been loaded
   */

  const init = (model, animations) => {
    // console.log('Technology :: init');

    // console.log('model');
    // console.log(model);

    // console.log('animations');
    // console.log(animations);

    for (const name of animations.names) {
      // console.log(name);
      const action = animations.actions[name]
      // console.log(action);
      action.play()
    }

    // apply the EmissiveMaterial to the correct model nodes
    for (const nodeName in model.nodes) {
      if (nodeName.toLowerCase().includes("emissive")) {
        const node = model.nodes[nodeName]
        node.material = emissiveMaterial
      }
    }
  }

  /*
   * render
   */

  useFrame((state, delta) => {
    emissiveMaterial.render(state.clock.elapsedTime, delta)
  })

  /*
   * visuals
   */

  return (
    <group>
      <Site
        name="technology"
        cameraOrder={[0, 3, 1, 2]}
        cameraScale={0.5}
        focusDistance={0.08}
        focusLength={0.12}
        init={init}
        // url={'/models/technology/SP_Tech_rexpose.glb'}
        // url={'/models/technology/SP_Tech.glb'}
        // url={'/models/technology/resize.glb'}
        // url={'/models/technology/ktx-ETC1S.glb'}
        // url={'/models/technology/ktx-ETC1S-draco.glb'}
        // url={'/models/technology/rexpose-ktx-ETC1S-draco.glb'}
        url={"/models/technology/resize-ktx-ETC1S-draco.glb"}
        screenTextures={screenTextures}
        ktx2Loader={ktx2Loader}
        dracoLoader={dracoLoader}
      />
    </group>
  )
}

class EmissiveMaterial extends THREE.MeshBasicMaterial {
  constructor() {
    super()
    this.color.setHSL(0, 0.5, 0.5)

    //this.transparent = true;
    //this.hue = 0;
  }

  render(elapsedTime, delta) {
    // // hue:
    // // .555 = magenta-ish
    // // .777 = blue-ish
    let hue = Math.sin(elapsedTime * 1)
    hue = (hue + 1) * 0.5 //normalize from 0 to 1

    // let lightness = Math.sin(elapsedTime * 4);
    // lightness = (lightness + 1) * 0.5; //normalize from 0 to 1
    //this.opacity = .65 + lightness * .35;

    this.color.setHSL(0.5 + 0.3 * hue, 1, 0.5)

    const extra = 1 // + lightness * 1.2;

    this.color.r += extra
    this.color.g += extra
    this.color.b += extra
  }
}

import style from './ModalMenuTitle.module.css';

export function ModalMenuTitle({ label, spaced }) {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */
  return (
    <>
      <p className={`${style.title} ${spaced ? style.spaced : ''}`}>{label}</p>
    </>
  );
}

import { useEffect, useMemo, useRef } from "react"
import * as THREE from "three"

import { useSite } from "../../stores/useSite"
import { useCameras } from "../../stores/useCameras"
import { useDepthOfField } from "../../stores/useDepthOfField"
import { useThree } from "@react-three/fiber"
import { PerspectiveCamera, useAnimations, useGLTF } from "@react-three/drei"
import { Ocean } from "./Ocean"
import { MathUtils } from "three"

import { useControls } from "leva"
import { HomeHotspot } from "../components/HomeHotspot"
import { useOverview } from "../../stores/useOverview"

// const ktx2Loader = new KTX2Loader().setTranscoderPath('loader/basis/');
// const dracoLoader = new DRACOLoader();
// dracoLoader.setDecoderPath('loader/draco/gltf/');

export function Home({ ktx2Loader, dracoLoader }) {
  //CAMERA TEST properties

  // const camera = useRef();

  // const params = useControls('Home', {
  //   cameraPosition: {
  //     // value: { x: 8.418272972106934, y: 7.258135795593262, z: 6.281443119049072 }, // ORIGINAL
  //     // value: { x: 5.5, y: 4.2, z: 4.4}, // OVERVIEW
  //     value: { x: 2.05, y: 2.6, z: 1.95 }, // BUSINESS
  //     // value: { x: 3.10, y: 2.60, z: 4.15}, // CONSTRUCTION
  //     // value: { x: 5.80, y: 2.60, z: 2.50}, // DEFENSE
  //     // value: { x: 3.10, y: 2.60, z: 2.00}, // HEALTHCARE
  //     // value: { x: 3.10, y: 2.60, z: 0.60}, // MANUFACTURING
  //     // value: { x: 2.1, y: 2.6, z: 2.95 }, // TECHNOLOGY
  //     min: -10,
  //     max: 10,
  //     step: 0.05,
  //   },
  //   cameraRotation: {
  //     value: { x: -0.9012889652357761, y: 0.6570189135910667, z: 0.6572412889279725 },
  //     min: -10,
  //     max: 10,
  //     step: 0.05,
  //   },
  // });

  /*
   * properties
   */

  // const { sites } = useOverview()
  const sites = useOverview((state) => state.sites)

  // const { setSite } = useSite()
  // const { setCameras } = useCameras()
  // const { setDepthOfField } = useDepthOfField()
  const setSite = useSite((state) => state.setSite)
  const setCameras = useCameras((state) => state.setCameras)

  const { gl } = useThree()

  const model = useGLTF(
    "/models/home/ktx-ETC1S-draco.glb",
    false,
    false,
    (loader) => {
      //KTX2
      loader.setKTX2Loader(ktx2Loader.detectSupport(gl))

      //DRACO
      loader.setDRACOLoader(dracoLoader)
    },
  )
  const animations = useAnimations(model.animations, model.scene)

  //CAMERA TEST methods

  // useEffect(() => {
  //   window.addEventListener('resize', resizeHandler);
  // }, []);

  // const resizeHandler = (e) => {
  //   // const src = useCameras.getState().cameras[0];
  //   const fov = 30;
  //   const cam = camera.current;
  //   const ratio = 1 / 1;

  //   if (cam.aspect > ratio) {
  //     //window too wide
  //     cam.fov = fov;
  //   } else {
  //     // window too narrow
  //     const cameraHeight = Math.tan(MathUtils.degToRad(fov * 0.5));
  //     const rat = cam.aspect / ratio;
  //     cam.fov = MathUtils.radToDeg(Math.atan(cameraHeight / rat)) * 2;
  //   }
  // };

  /*
   * hooks
   */

  // useEffect(() => {
  //   setSite('home');
  // }, []);

  // set the main global properties when the current Site is activated
  useEffect(() => {
    setSite("home")

    // console.log('model');
    // console.log(model);

    const cam = model.cameras[0]
    setCameras([cam], 0.4)

    // const focusDistance = 0.08
    // const focusLength = 0.1
    // setDepthOfField(focusDistance, focusLength)
  }, [model])

  useEffect(() => {
    // console.log('animations');
    // console.log(animations);

    const action = animations.actions["Animation"]

    // console.log('action');
    // console.log(action);

    action.play()
  }, [animations])

  return (
    <>
      <group dispose={null}>
        <primitive object={model.scene} />

        {sites &&
          model &&
          sites.map((value, index) => (
            <HomeHotspot
              key={index}
              node={model.nodes[value.hotspot]}
              hoverBlock={model.nodes[value.hoverBlock]}
              data={value}
            />
          ))}

        <Ocean />
      </group>

      {/* <PerspectiveCamera
        ref={camera}
        position={[params.cameraPosition.x, params.cameraPosition.y, params.cameraPosition.z]}
        rotation={[params.cameraRotation.x, params.cameraRotation.y, params.cameraRotation.z]}
        makeDefault={false}
        near={0.1}
        far={100}
      /> */}
    </>
  )
}
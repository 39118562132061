import { create } from "zustand"
import { getSettings, localize } from "../utils/sanity"

export const useSettings = create((set, get) => ({
  loadingSettings: true,
  hasError: false,
  settings: {},
  sections: {
    construction: "Construction",
    defense: "Defense",
    manufacturing: "Manufacturing",
    healthcare: "Healthcare",
    technology: "Information, Technology & Data",
    business: "Business, Finance & Marketing",
  },

  fetchSettings: async (locale) => {
    try {
      const response = await getSettings()
      const localizedResponse = await localize(response, locale)
      // console.log("useSettings :: response")
      // console.log(localizedResponse)
      set({ settings: localizedResponse })
      // console.log({ settings: localizedResponse })
    } catch (err) {
      console.log(err)
      set({ hasError: true })
    } finally {
      set({ loadingSettings: false })
    }
  },
  getLabel: (section, label) => {
    if (
      get().settings &&
      get().settings[section] &&
      get().settings[section][label]
    ) {
      if (get().settings[section][label]) {
        return get().settings[section][label]
      } else if (!translated) {
        return get().settings[section][label]
      }
    }
    return section + "-" + label
  },
}))

import { useEffect, useRef, useState } from 'react';
import { OrbitControls, PerspectiveCamera } from '@react-three/drei';
import { MathUtils } from 'three';

import gsap from 'gsap';

import { useSite } from '../../stores/useSite';
import { useCameras } from '../../stores/useCameras';
import { useTransit } from '../../stores/useTransit';
import { useHotspot } from '../../stores/useHotspot';
import { useMouse } from '../../stores/useMouse';
import { useFrame } from '@react-three/fiber';
import { useDepthOfField } from '../../stores/useDepthOfField';
import { useSize } from '../../stores/useSize';
import { useOverview } from '../../stores/useOverview';

export function CameraManager() {
  /*
   * properties
   */

  // camera properties
  const camera = useRef();
  const wrapper = useRef();
  const ratio_site = 5 / 3; //OTHER SITES
  const ratio_home = 1 / 1.15; //HOME

  // state properties
  const { site, level, nodes } = useSite();
  const { cameraScale, startCameraTransition, endCameraTransition } = useCameras();
  const { activeHotspot, getHotspot } = useHotspot();
  const { slowPctX, slowPctY } = useMouse();
  const { setBokeh } = useDepthOfField();
  const { transition } = useTransit();
  const { isMobile } = useSize();
  const { activeSite } = useOverview();

  /*
   * hooks
   */

  // set camera FOV after resizing

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
  }, []);

  const resizeHandler = (e) => {
    // console.log('resizeHandler');
    // console.log('ratio_home: ' + ratio_home);
    // console.log('ratio_site: ' + ratio_site);
    // console.log('site: ' + useSite.getState().site);

    const ratio = useSite.getState().site === 'home' ? ratio_home : ratio_site;
    // const ratio = ratio_home;

    // console.log('ratio: ' + ratio);

    const src = useCameras.getState().cameras[0];
    const fov = src && src.fov ? src.fov : 25;
    const cam = camera.current;

    if (cam.aspect > ratio) {
      //window too wide
      cam.fov = fov;
    } else {
      // window too narrow
      const cameraHeight = Math.tan(MathUtils.degToRad(fov * 0.5));
      const rat = cam.aspect / ratio;
      cam.fov = MathUtils.radToDeg(Math.atan(cameraHeight / rat)) * 2;
    }
  };

  // camera start animation when site is shown

  useEffect(() => {
    if (site) {
      // console.log('CameraManager :: start');

      //reset previous animation
      gsap.killTweensOf(wrapper.current.position);
      gsap.killTweensOf(wrapper.current.rotation);

      // set camera start position
      let src = useCameras.getState().cameras[0];
      wrapper.current.position.copy(src.position);
      wrapper.current.rotation.copy(src.rotation);

      // start tween
      setBokeh(false);
      const startDuration = 3;

      if (site === 'home') {
        // subtle start tween
        gsap.from(wrapper.current.position, {
          duration: startDuration,
          y: src.position.y - 2 * cameraScale,
          ease: 'sine.inOut',
        });
      } else {
        // subtle start tween
        gsap.from(wrapper.current.position, {
          duration: startDuration,
          y: src.position.y + 2 * cameraScale,
          ease: 'sine.inOut',
        });

        //set fov
        camera.current.fov = src.fov;

        endCameraTransition('start');

        // tween camera to level 1
        src = useCameras.getState().cameras[1];
        tweenCamera(
          src.position,
          src.rotation,
          2.5,
          startDuration + 0.1,
          () => {
            startCameraTransition();
            setBokeh(true);
          },
          () => {
            endCameraTransition('intro');
          }
        );
      }

      resizeHandler();
    }
  }, [site]);

  //play camera sequence when the level is changed
  useEffect(() => {
    if (transition === 'finished') {
      // tween camera to the current level's position
      const src = useCameras.getState().cameras[level];

      tweenCamera(src.position, src.rotation, 1.5, 0, startCameraTransition, () => {
        endCameraTransition('level');
      });
    }
  }, [level]);

  //zoom
  useEffect(() => {
    // console.log('*** activeSite: ' + activeSite);
    // console.log(activeSite);

    const src = useCameras.getState().cameras[0];
    // console.log(src);

    if (src && activeSite) {
      // tweenCamera({activeSite.cameraPosition}, src.rotation, 0.75);
      if (isMobile) {
        tweenCamera(activeSite.cameraPosition, src.rotation, 0.75);
      } else {
        tweenCamera(
          {
            x: (src.position.x + activeSite.cameraPosition.x) * 0.5,
            y: (src.position.y + activeSite.cameraPosition.y) * 0.5,
            z: (src.position.z + activeSite.cameraPosition.z) * 0.5,
          },
          src.rotation,
          0.75
        );
      }
    } else if (src) {
      tweenCamera(src.position, src.rotation, 0.5);
    }
  }, [activeSite]);

  // tween camera to the focused hotspot
  // useEffect(() => {
  //   //for mobile
  //   //if (activeHotspot && isMobile) {
  //   if (activeHotspot) { //TEMP
  //     // focus on the hotspot

  //     const hotspot = getHotspot(activeHotspot, site);
  //     // console.log('CameraManager :: focus');
  //     // console.log(hotspot);

  //     if (hotspot.level === level) {
  //       const src = nodes[activeHotspot];
  //       const focusPos = src.position;

  //       const startRotation = wrapper.current.rotation.clone();

  //       wrapper.current.rotation.set(0, 0, 0);
  //       camera.current.lookAt(focusPos.x, focusPos.y, focusPos.z);
  //       const endRotation = camera.current.rotation.clone();

  //       wrapper.current.rotation.copy(startRotation);
  //       camera.current.rotation.set(0, 0, 0);

  //       // console.log('startRotation - endRotation');
  //       // console.log(startRotation);
  //       // console.log(endRotation);

  //       tweenCamera(wrapper.current.position.clone(), endRotation, 0.5);
  //     }
  //   }
  // }, [activeHotspot]);

  // tween the camera

  const tweenCamera = (position, rotation, duration, delay = 0, startCallback = null, completeCallback = null) => {
    gsap.killTweensOf(wrapper.current);
    gsap.killTweensOf(wrapper.current.position);
    gsap.killTweensOf(wrapper.current.rotation);

    if (site === 'manufacturing' && level === 3) {
      // value: { x: 0, y: -1.84, z: 0 },
      rotation.x = 0;
      rotation.y = -1.84;
      rotation.z = 0;
    }

    //FIX FOR MANUFACTURING SCENE
    // if (rotation.x > Math.PI * 0.5) {
    // rotation.x -= Math.PI;
    // rotation.x = rotation.x - Math.PI * 2;
    // rotation.x = 0;
    // console.log('PRE')
    // console.log('x', ratToDeg(rotation.x));
    // console.log('y', ratToDeg(rotation.y));
    // console.log('z', ratToDeg(rotation.z));
    // rotation.x = 0;
    // rotation.y = -1.8;
    // rotation.z = 0;
    // console.log('POST')
    // console.log('x', ratToDeg(rotation.x));
    // console.log('y', ratToDeg(rotation.y));
    // console.log('z', ratToDeg(rotation.z));
    // }

    // if (rotation.x < -Math.PI * 0.5) {
    //   rotation.x += Math.PI * 2;
    // }
    // if (rotation.z < -Math.PI * 0.5) {
    //   rotation.z += Math.PI * 2;
    // }

    // console.log('tweenCamera');
    // console.log('delay: ' + delay);

    // console.log('FROM:');
    // console.log(wrapper.current.rotation);
    // console.log('TO:');
    // console.log(rotation);

    const ease = 'power1.inOut';

    gsap.to(wrapper.current.position, { delay, duration, x: position.x, y: position.y, z: position.z, ease });
    gsap.to(wrapper.current.rotation, {
      delay,
      duration,
      x: rotation.x,
      y: rotation.y,
      z: rotation.z,
      ease,
      onStart: startCallback,
      onComplete: completeCallback,
    });
  };

  function ratToDeg(radians) {
    var pi = Math.PI;
    return radians * (180 / pi);
  }

  //move camera on mouse move

  useFrame(() => {
    camera.current.position.x = slowPctX * 0.2 * cameraScale;
    camera.current.position.y = -slowPctY * 0.2 * cameraScale;

    camera.current.rotation.y = slowPctX * 0.01 * cameraScale;
    camera.current.rotation.x = slowPctY * 0.01 * cameraScale;
  });

  /*
   * visuals
   */

  return (
    <>
      <group ref={wrapper}>
        {/* <PerspectiveCamera ref={camera} makeDefault={true} near={0.1} far={100} /> */}
        <PerspectiveCamera ref={camera} makeDefault={true} near={0.1} far={100} />
      </group>
      {/* <OrbitControls makeDefault dampingFactor={1} /> */}
    </>
  );
}

import style from "./App2D.module.css"

import { LevelMenu } from "./components/LevelMenu"
import { HotspotMenu } from "./components/HotspotMenu"
import { SiteMenu } from "./components/SiteMenu"
import { Modal } from "./modal/Modal"
import { MainLoader } from "./transit/MainLoader"
import { Transit } from "./transit/Transit"
import { MainMenu } from "./components/MainMenu"
import { ModalDisplayMenu } from "./components/ModalDisplayMenu"
import { SizeController } from "./controllers/SizeController"
import { MouseController } from "./controllers/MouseController"
import { useSite } from "../stores/useSite"
import { Overview } from "./overview/Overview"
import { OverviewLabel } from "./overview/OverviewLabel"
import { Guide } from "./howto/Guide"

export function App2D() {
  // const { site } = useSite()
  const site = useSite((state) => state.site)

  return (
    <>
      {/* controllers */}

      <SizeController />
      <MouseController />

      {/* visible elements */}

      <MainMenu />

      {site === "home" && (
        <>
          <OverviewLabel />
          <Overview />
        </>
      )}

      <SiteMenu />

      <ModalDisplayMenu />
      <LevelMenu />
      <HotspotMenu />

      <Modal />

      <Transit />
      <MainLoader />

      <Guide />
    </>
  )
}

import { create } from 'zustand';

export const useGuide = create((set) => ({
  shown: false, // wheter or not the modal is visible

  // show the modal
  showGuide: () => set({ shown: true }),

  // hide the modal
  hideGuide: () => set({ shown: false }),
}));

import { Icon } from "../../../components/Icon"
import style from "./ProgrammePanel.module.css"
import { useEffect } from "react"
import { getImage, getLogo } from "../../../../utils/sanityImages"
import Localize from "../../../components/Localize"

export function ProgrammePanel({ data }) {

  useEffect(() => {
    console.log("ProgrammePanel :: data")
    console.log(data)
    // console.log("ProgrammePanel :: tags")
    // console.log(data.tags)
  }, [data])

  return (
    <div className={style.container}>
      <div className={style.media}>
        <img
          className={style.image}
          alt={data.thumbnail.alt}
          src={getImage(data.thumbnail, 500, 500)}
        />
        {data.promoted && (
          <div className={style.promoted}>
            <Localize label="ui-terms.promoted" />
          </div>
        )}
      </div>
      <div className={style.content}>
        <div className={style.header}>
          <img
            className={style.logo}
            alt={data.logo.alt}
            src={getLogo(data.logo)}
          />
          <div className={style.headerCopy}>
            <h3 className={style.title}>{data.subtitle && data.subtitle}</h3>
            <h3 className={style.client}>{data.client}</h3>
          </div>
        </div>
        {data.tags && data.tags.length > 0 && (
          <div className={style.tags}>
            {data.tags.map((value, index) => (
              <div key={index} className={style.tag}>
                {value.toUpperCase()}
              </div>
            ))}
          </div>
        )}
        <p className={style.criteria}>
          <span className={style.icon}>
            <Icon svg="info" size="small" />
          </span>
          <Localize
            tag="div"
            label="workExperiencePrograms.elgibilityCrietia"
          />
        </p>
        <p className={style.body}>{data.intro}</p>
      </div>
      <a className={style.button} href={data.courseLink} target="_blank">
        <Localize label="employability.cta" />
      </a>
    </div>
  )
}

import style from './ModalDisplayMenu.module.css';

import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import gsap from 'gsap';

import { useSite } from '../../stores/useSite';
import { useTransit } from '../../stores/useTransit';
import { useCameras } from '../../stores/useCameras';
import { useModal } from '../../stores/useModal';
import { Icon } from './Icon';
import { useSize } from '../../stores/useSize';
import { useHotspot } from '../../stores/useHotspot';
import { useSettings } from '../../stores/useSettings';

export function ModalDisplayMenu() {
  /*
   * properties
   */

  const getLabel = useSettings((state) => state.getLabel)

  // const { site } = useSite();
  // const { transition } = useTransit();
  // const { cameraStatus } = useCameras();
  // const { showModal, hideModal } = useModal();
  // const { unsetActiveHotspot } = useHotspot();
  // const { isMobile } = useSize();

  const location = useLocation();

  const site = useSite((state) => state.site)
  const transition = useTransit((state) => state.transition)
  const cameraStatus = useCameras((state) => state.cameraStatus)
  const showModal = useModal((state) => state.showModal)
  const hideModal = useModal((state) => state.hideModal)
  const unsetActiveHotspot = useHotspot((state) => state.unsetActiveHotspot)
  const isMobile = useSize((state) => state.isMobile)

  const container = useRef();
  // const openButton = useRef();
  // const closeButton = useRef();

  /*
   * hooks
   */

  // hide in case the meny wasn't already hidden (when containerigation with prev/next buttons in browser)
  useEffect(() => {
    gsap.set(container.current, { autoAlpha: 0 });
  }, [location]);

  // // show/hide openButton en closeButton
  // useEffect(() => {
  //   // console.log('ModalMenu :: shown hook');
  //   // console.log('shown', shown);
  //   // if (shown) {
  //   //   hideButton(openButton.current);
  //   //   showButton(closeButton.current);
  //   // } else {
  //   //   showButton(openButton.current);
  //   //   hideButton(closeButton.current);
  //   // }
  // }, [shown]);

  /*
   * hide
   */

  useEffect(() => {
    if (transition === 'out') {
      // hide menu when the out transition starts
      gsap.killTweensOf(container.current);
      gsap.to(container.current, { y: 30, autoAlpha: 0, duration: 0.3, ease: 'power2.in' });
    }
  }, [transition]);

  /*
   * show
   */

  useEffect(() => {
    if (cameraStatus === 'intro' && site !== 'home') {
      // show menu when the transitions have finished
      gsap.killTweensOf(container.current);
      gsap.set(container.current, { y: 0, autoAlpha: 1, scale: 1 });
      gsap.from(container.current, { delay: 0.15, y: 30, autoAlpha: 0, duration: 0.7, ease: 'power4.out' });
    }
  }, [cameraStatus]);

  /*
   * interaction
   */

  // on click
  const clickHandler = () => {
    if (useModal.getState().shown) {
      hideModal();
    } else {
      if (isMobile) {
        unsetActiveHotspot();
      }
      showModal();
    }
    // if (state === 'show') {
    //   //   console.log('show Modal');
    //   showModal();
    // } else {
    //   //   console.log('hide Modal');
    //   hideModal();
    // }
  };

  // // hide the given button
  // const hideButton = (button) => {
  //   gsap.killTweensOf(button);
  //   gsap.to(button, { autoAlpha: 0, scale: 0.9, duration: 0.2, ease: 'power2.out' });
  // };

  // // show the given button
  // const showButton = (button) => {
  //   gsap.killTweensOf(button);
  //   gsap.to(button, { delay: 0.6, autoAlpha: 1, scale: 1, duration: 0.3, ease: 'power2.in' });
  // };

  /*
   * visuals
   */

  // return <>{isMobile ? <OverviewMobile /> : <OverviewDesktop />}</>;

  return (
    <>
      {/* <button ref={container} className={style.button} onClick={clickHandler}>
        <div className={style.icon}>
          <Icon svg="hamburger" light size="small" />
        </div>
        
      </button> */}
      <button
        ref={container}
        className={`${style.button} ${isMobile ? style.mobile : style.desktop}`}
        onClick={clickHandler}
      >
        <div className={`${style.icon} ${isMobile ? style.mobile : style.desktop}`}>
          <Icon svg="hamburger" light size="small" />
        </div>
        {!isMobile && getLabel("ui-terms", "menu").toUpperCase()}
      </button>
    </>
  );
}

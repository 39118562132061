import { useMemo } from "react"
import { RoundButton } from "../../../components/RoundButton"
import style from "./NextVideo.module.css"
import { getPosterImage } from "../../../../utils/sanityImages"

export function NextVideo({ data, setNextVideo }) {
  const posterUrl = useMemo(() => {
    console.log("NextVideo :: get poster image")
    if (data && data.poster) {
      return getPosterImage(data.poster)
    }

    return ""
  }, [data.poster])

  return (
    <div data-video="next" className={style.container}>
      <div className={style.preview}>
        <img className={style.image} src={posterUrl} />
        <div className={style.playButtonWrapper}>
          <RoundButton
            onClick={() => setNextVideo(data._key)}
            icon="play"
            color="secondary"
          />
        </div>
      </div>
      <div className={style.content}>
        <h3 className={style.title}>{data.title}</h3>
        <p className={style.paragraph}>{data.length}</p>
      </div>
    </div>
  )
}

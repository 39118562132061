import { useSize } from "../../stores/useSize"
import style from "./LevelMenu.module.css"

import { LevelMenuDesktop } from "./LevelMenu/LevelMenuDesktop"
import { LevelMenuMobile } from "./LevelMenu/LevelMenuMobile"

export function LevelMenu() {
  /*
   * properties
   */
  // const { isMobile } = useSize()

  /*
   * visuals
   */

  return (
    <>
      <LevelMenuDesktop />
      <LevelMenuMobile />
    </>
  )
}

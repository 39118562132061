import { useRef } from "react"
import { NextVideo } from "./NextVideo"
import style from "./NextVideos.module.css"
import { useEffect } from "react"
import { useState } from "react"
import Localize from "../../../components/Localize"

export function NextVideos({ id, panels, setNextVideo }) {
  /*
   * properties
   */

  const container = useRef()
  const [showBottom, setShowBottom] = useState()

  /*
   * hooks
   */

  useEffect(() => {
    setBottom()
  }, [])

  const setBottom = () => {
    // console.log(container.current.scrollTop);
    // console.log(container.current.scrollHeight);
    // console.log(container.current.clientHeight);

    const isScrollable =
      container.current.scrollHeight > container.current.clientHeight

    setShowBottom(container.current.scrollTop === 0 && isScrollable)
  }

  /*
   * visuals
   */

  return (
    <div ref={container} className={style.container} onScroll={setBottom}>
      <Localize label="ui-terms.nextVideo" tag="h3" />

      {panels.map(
        (value, index) =>
          value.id !== id && (
            <NextVideo setNextVideo={setNextVideo} key={index} data={value} />
          ),
      )}

      <div className={`${style.bottom} ${showBottom ? style.isShown : ""}`} />
    </div>
  )
}

import style from "./SiteMenu.module.css"

import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import gsap from "gsap"

import { useSite } from "../../stores/useSite"
import { useTransit } from "../../stores/useTransit"
import { RoundButton } from "./RoundButton"
import { useCameras } from "../../stores/useCameras"
import { useSettings } from "../../stores/useSettings"
import { useHotspot } from "../../stores/useHotspot"

export function SiteMenu() {
  /*
   * properties
   */

  const getLabel = useSettings((state) => state.getLabel)
  // const settings = useSettings((state) => state.sections)

  // const { sanityHotspots } = useHotspot()
  // const { site, level } = useSite()
  // const { transition, startTransitionOut } = useTransit()
  // const { cameraStatus } = useCameras()

  const sanityHotspots = useHotspot((state) => state.sanityHotspots)
  const site = useSite((state) => state.site)
  const level = useSite((state) => state.level)
  const transition = useTransit((state) => state.transition)
  const startTransitionOut = useTransit((state) => state.startTransitionOut)
  const cameraStatus = useCameras((state) => state.cameraStatus)

  const location = useLocation()

  const container = useRef()

  /*
   * hooks
   */

  //hide in case the meny wasn't already hidden (when containerigation with prev/next buttons in browser)
  useEffect(() => {
    gsap.set(container.current, { autoAlpha: 0 })
  }, [location])

  // show/hide menu
  useEffect(() => {
    if (transition === "out") {
      // hide menu when the out transition starts
      gsap.killTweensOf(container.current)
      gsap.to(container.current, {
        x: -30,
        autoAlpha: 0,
        duration: 0.3,
        ease: "power2.in",
      })
    }
  }, [transition])

  // camera status related logic
  useEffect(() => {
    if (cameraStatus === "intro" && site !== "home") {
      // show menu when the transitions have finished
      gsap.killTweensOf(container.current)
      gsap.set(container.current, { x: 0, autoAlpha: 1, scale: 1 })
      gsap.from(container.current, {
        x: -30,
        autoAlpha: 0,
        duration: 0.7,
        ease: "power4.out",
      })
    }
  }, [cameraStatus])

  /*
   * interaction
   */

  // on click
  const clickHandler = () => {
    // go (back) home
    startTransitionOut("/")
  }

  const localizedSiteName =
    sanityHotspots &&
    sanityHotspots.industries &&
    sanityHotspots.industries.find((industry) => industry.uid === site)
      ?.industryName

  /*
   * visuals
   */

  return (
    <div ref={container} className={style.container}>
      <RoundButton
        color="transparent"
        icon="arrow-back"
        onClick={clickHandler}
      />
      <div>
        <span className={`${style.level} ${style["level" + level]}`}>
          {getLabel("ui-terms", "level").toUpperCase()} {level}
        </span>
        <h1 className={style.title}>{localizedSiteName}</h1>
      </div>
    </div>
  )
}

import { Environment } from "@react-three/drei"
import { useControls } from "leva"

export function Lighting() {
  /*
   * properties
   */

  // const config = useControls('ambient light', {
  //   ambientIntensity: { value: 0.75, min: 0, max: 2, step: 0.01, label: 'intensity' },
  //   ambientColor: { value: '#ffffff', label: 'color' },
  // });

  const config = {
    // ambientIntensity: 0.75,
    ambientIntensity: 2.5,
    ambientColor: "#ffffff",
  }

  /*
   * visuals
   */

  return (
    <>
      <ambientLight
        intensity={config.ambientIntensity}
        color={config.ambientColor}
      />

      <Environment
        background={false}
        path="/environmentMaps/3/"
        files={["px.jpg", "nx.jpg", "py.jpg", "ny.jpg", "pz.jpg", "nz.jpg"]}
      />
    </>
  )
}

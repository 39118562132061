import style from "./LevelMenuDesktop.module.css"

import { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import gsap from "gsap"

import { useSite } from "../../../stores/useSite"
import { useTransit } from "../../../stores/useTransit"
import { useCameras } from "../../../stores/useCameras"
import { useHotspot } from "../../../stores/useHotspot"
import { useWindowSize } from "@uidotdev/usehooks"
import { useSize } from "../../../stores/useSize"
import { useSettings } from "../../../stores/useSettings"

export function LevelMenuDesktop() {
  /*
   * properties
   */

  const [activeIndex, setActiveIndex] = useState(0)

  const getLabel = useSettings((state) => state.getLabel)
  const size = useWindowSize()
  const { isMobile } = useSize()
  const { site, level, setLevel } = useSite()
  const { unsetActiveHotspot } = useHotspot()
  const { transition } = useTransit()
  const { cameraStatus } = useCameras()

  const location = useLocation()

  const nav = useRef()
  const highlight = useRef()

  /*
   * hooks
   */

  //resize
  useEffect(() => {
    if (site && cameraStatus !== "transition") {
      //   setHighlight(activeIndex);
      setHighlight(level)
    }
  }, [size])

  // hide in case the menu wasn't already hidden (when navigation with prev/next buttons in browser)
  useEffect(() => {
    // console.log('LevelMenuDesktop :: hide in case it wasnt already');
    // console.log('nav.current');
    // console.log(nav.current);
    gsap.set(nav.current, { opacity: 0 })
  }, [location])

  //show in case the viewport is resized from mobile to desktop
  useEffect(() => {
    if (nav && !isMobile && transition === "finished" && site !== "home") {
      gsap.set(nav.current, { opacity: 1 })
    }
  }, [isMobile])

  // set the highlight when changing level
  useEffect(() => {
    setHighlight(level)
  }, [level])

  // transition state related logic
  useEffect(() => {
    // console.log('LevelMenu :: useEffect(transition)');
    // console.log('transition: ' + transition);

    if (transition === "out") {
      // hide menu when the out transition starts
      // console.log('LevelMenuDesktop :: HIDE');
      if (nav.current) {
        gsap.killTweensOf(nav.current)
        gsap.to(nav.current, {
          y: 30,
          autoAlpha: 0,
          duration: 0.3,
          ease: "power2.in",
        })
      }
    }
  }, [transition])

  // show menu when the in transition starts
  useEffect(() => {
    // console.log('SET CAMERA STATUS')
    // console.log(cameraStatus)
    if (cameraStatus === "intro" && site !== "home") {
      // console.log('LevelMenuDesktop :: SHOW');

      gsap.killTweensOf(nav.current)
      gsap.set(nav.current, { y: 0, opacity: 1, scale: 1 })
      gsap.from(nav.current, {
        delay: 0.3,
        y: 30,
        autoAlpha: 0,
        duration: 0.7,
        ease: "power4.out",
      })

      setHighlight(level)
    }
  }, [cameraStatus])

  /*
   * interaction
   */

  // on mouse over
  const overHandler = (overLevel) => {
    setHighlight(overLevel)
  }

  // on mouse out
  const outHandler = () => {
    setHighlight(level)
  }

  // on click
  const clickHandler = (newLevel) => {
    unsetActiveHotspot()
    setLevel(newLevel)
  }

  /*
   * set highlight
   */

  const setHighlight = (position) => {
    setActiveIndex(position)

    const element = nav && nav.current && nav.current.children[position + 1]

    if (element) {
      gsap.killTweensOf(highlight.current)
      gsap.to(highlight.current, {
        x: element.offsetLeft - 5,
        duration: 0.35,
        ease: "power4.out",
      })
    }
  }

  /*
   * visuals
   */

  return (
    <>
      {!isMobile && site !== "home" && (
        <nav ref={nav} className={style.nav}>
          <div className={style.title}>
            {getLabel("ui-terms", "level").toUpperCase()}
          </div>
          <div ref={highlight} className={style.highlight} />
          {[...Array(3)].map((value, index) => (
            <button
              key={index}
              className={`${style.button} ${
                activeIndex === index + 1 && style.active
              }`}
              onFocus={() => overHandler(index + 1)}
              onMouseOver={() => overHandler(index + 1)}
              onBlur={outHandler}
              onMouseOut={outHandler}
              onClick={() => clickHandler(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </nav>
      )}
    </>
  )
}

import style from './Icon.module.css';

export function Icon({ svg, light, size }) {
  return (
    <img
      className={`${style.icon} ${light ? style.light : ''} ${size ? style[size] : ''}`}
      aria-hidden="true"
      src={`/images/icons/${svg}.svg`}
    />
  );
}

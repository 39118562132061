import { create } from 'zustand';

export const useTransit = create((set) => ({
  transition: '', // the transition state ('out' > 'pause' > 'finished')
  route: '', // the route to where to go when the transition has finished

  // start the out transition
  startTransitionOut: (route) =>
    set((state) => {
      return state.route === route ? null : { transition: 'out', route };
    }),

  // start loading the new route
  startLoad: (route) => set({ transition: 'pause', route }),

  // end the transition
  endTransitionIn: () => set({ transition: 'finished' }),
}));

import { create } from 'zustand';

export const useOverview = create((set, get) => ({
  activeSite: null, // the data of the active site

  // all of the site data
  sites: [
    {
      id: 'construction',
      route: '/construction',
      hotspot: 'Hotspot_Construction_001',
      hoverBlock: 'Construction_Block_Hover',
      cameraPosition: { x: 3.1, y: 2.6, z: 4.15 },
    },
    {
      id: 'defense',
      route: '/defense',
      hotspot: 'Hotspot_Defense_006',
      hoverBlock: 'Defense_Block_Hover',
      cameraPosition: { x: 5.8, y: 2.6, z: 2.5 },
    },
    {
      id: 'manufacturing',
      route: '/manufacturing',
      hotspot: 'Hotspot_Manufacturing_005',
      hoverBlock: 'Manufacturing_Block_Hover',
      cameraPosition: { x: 3.1, y: 2.6, z: 0.6 },
    },
    {
      id: 'healthcare',
      route: '/healthcare',
      hotspot: 'Hotspot_Healtcare_004',
      hoverBlock: 'Health_Block_Hover',
      cameraPosition: { x: 3.1, y: 2.6, z: 2.0 },
    },
    {
      id: 'technology',
      route: '/technology',
      hotspot: 'Hotspot_Tech_002',
      hoverBlock: 'Tech_Block_Hover',
      cameraPosition: { x: 2.1, y: 2.6, z: 2.95 },
    },
    {
      id: 'business',
      route: '/business',
      hotspot: 'Hotspot_Business_003',
      hoverBlock: 'Business_Block_Hover',
      cameraPosition: { x: 2.05, y: 2.6, z: 1.95 },
    },
  ],

  // set the currently active site (by id)
  setActiveSite: (activeSite) => set({ activeSite }),

  // reset the currenly active site
  unsetActiveSite: () => set({ activeSite: null }),
}));

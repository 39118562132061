import { Icon } from "../../../components/Icon"
import style from "./EmployabilityPanelMobile.module.css"
import { getImage } from "../../../../utils/sanityImages"
import Localize from "../../../components/Localize"

export function EmployabilityPanelMobile({ data }) {
  return (
    <div className={style.container}>
      <div className={style.imageWrapper}>
        <img className={style.image} src={getImage(data.thumbnail, 500, 500)} />
      </div>
      <div className={style.content}>
        <div className={style.title}>{data.name}</div>
        <div className={style.body}>{data.body}</div>
        <a href={data.link} target="_blank" className={style.button}>
          <Localize label="employability.cta" />
          &nbsp;
          <div className={style.icon}>
            <Icon svg="arrow-next" size="small" />
          </div>
        </a>
      </div>
    </div>

    // <div className={style.container}>
    //   <div className={style.imageWrapper}>
    //     <img className={style.image} src="https://picsum.photos/500/500" />
    //   </div>
    //   <div className={style.content}>
    //     <div className={style.title}>Employability skill name</div>
    //     <div className={style.body}>
    //       Employability skill introductory copy goes here and would be visibile on hover.
    //     </div>
    //     <a href="http://www.springpod.com" target="_blank" className={style.button}>
    //       <span>Start now</span>
    //       <div className={style.icon}>
    //         <Icon svg="arrow-next" size="small" />
    //       </div>
    //     </a>
    //   </div>
    // </div>
  )
}

import style from './ContentTitle.module.css';

export function ContentTitle({ label, emoticon }) {
  return (
    <h2 className={style.title}>
      <span>{label}</span>
      <span>&nbsp;{emoticon}</span>
    </h2>
  );
}

import { useRef } from "react"
import { Site } from "./Site"
import {
  PerspectiveCamera,
  useTexture,
  useVideoTexture,
} from "@react-three/drei"
import { useControls } from "leva"

export function Manufacturing({ ktx2Loader, dracoLoader }) {
  /*
   * properties
   */

  // const camera = useRef();

  // const props = useControls('manufacuring camera', {
  //   position: {
  //     value: { x: -3.6313018798828125, y: 0.38180622458457947, z: -1.2150474786758423 },
  //     min: -4,
  //     max: 4,
  //     step: 0.001,
  //   },
  //   // rotation: {
  //   //   value: { x: -3.0640764696721257, y: -1.300774291327027, z: -3.06687663564196 },
  //   //   min: -4,
  //   //   max: 4,
  //   //   step: 0.001,
  //   // },
  //   rotation: {
  //     value: { x: 0, y: -1.84, z: 0 },
  //     min: -4,
  //     max: 4,
  //     step: 0.001,
  //   },
  // });

  // textures and videos
  // const test_screen_bars = useTexture('/textures/test_screen_bars.jpg');
  // const video1 = useVideoTexture('/video/glitch.mp4');

  const uv_test = useTexture("/textures/uv_test.jpg")

  // link textures and/or video's to model nodes
  const screenTextures = {
    // Screen_Info_Point_Lvl01: video1,
    // Screen_Lvl01: test_screen_bars,
    Screen_Info_Point_Lvl01001: uv_test,
    Screen_Lvl01001: uv_test,
  }

  /*
   * init: run Site specific code after the modal has been loaded
   */

  const init = (model, animations) => {
    // console.log('Manufacturing :: init');

    // console.log('model');
    // console.log(model);
    // console.log('model.nodes.Ground.position');
    // console.log(model.nodes.Ground.position);

    // console.log('model.nodes.Exterior_Stroke.position PRE');
    // console.log(model.nodes.Exterior_Stroke.position);

    // console.log('depthWrite PRE');
    // console.log(model.nodes.Exterior_Stroke.material.depthWrite);

    model.nodes.Ground.material.depthWrite = true
    // model.nodes.Exterior_Stroke.material.depthWrite = true;
    // console.log('depthWrite POST');
    // console.log(model.nodes.Exterior_Stroke.material.depthWrite);

    const action = animations.actions["Animation"]
    action.play()
  }

  /*
   * visuals
   */

  return (
    <group>
      <Site
        name="manufacturing"
        // cameraOrder={[2, 3, 1, 0]}
        cameraOrder={[0, 1, 2, 3]}
        cameraScale={0.2}
        focusDistance={0.04}
        focusLength={0.04}
        init={init}
        // url={'/models/manufacturing/02_Manufacturing_008.glb'}
        // url={'/models/manufacturing/resize.glb'}

        // url={'/models/manufacturing/resize-ktx-ETC1S-draco.glb'}
        url={"/models/manufacturing/02_Manufacturing_011.glb"}
        // url={'/models/manufacturing/resize-ktx-ETC1S.glb'}
        // url={'/models/manufacturing/resize-ktx-UASTC-draco.glb'}
        // url={'/models/manufacturing/resize-ktx-UASTC.glb'}

        // url={'/models/manufacturing/_bak/resize-ktx-ETC1S-draco.glb'}

        screenTextures={screenTextures}
        ktx2Loader={ktx2Loader}
        dracoLoader={dracoLoader}
      />
      {/* <PerspectiveCamera
        ref={camera}
        makeDefault={false}
        near={0.1}
        far={100}
        // position={[-3.6313018798828125, 0.38180622458457947, -1.2150474786758423]}
        // rotation={[-3.0640764696721257, -1.300774291327027, -3.06687663564196]}
        position={[props.position.x, props.position.y, props.position.z]}
        rotation={[props.rotation.x, props.rotation.y, props.rotation.z]}
      /> */}
    </group>
  )
}

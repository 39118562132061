import { create } from "zustand"

export const useSize = create((set) => ({
  isMobile: false, // whether or not the project is in mobile mode
  dpr: Math.min(2, window.devicePixelRatio), //TO DO
  antialias: true,

  // set the mobile mode
  setMobile: (isMobile) => set({ isMobile }),
  setDpr: (dpr) => set({ dpr }),
  setAntialias: (antialias) => set({ antialias }),
}))

import style from "./HomeHotspot.module.css"

import { useEffect, useMemo, useRef, useState } from "react"
import { Html, MeshDiscardMaterial, useCursor } from "@react-three/drei"
import gsap from "gsap"

import { useModal } from "../../stores/useModal"
import { useSite } from "../../stores/useSite"
import { useTransit } from "../../stores/useTransit"
import { useHotspot } from "../../stores/useHotspot"
import { useFrame } from "@react-three/fiber"
import { useMouse } from "../../stores/useMouse"
import { Icon } from "../../app2D/components/Icon"
import { useOverview } from "../../stores/useOverview"
import { useSize } from "../../stores/useSize"

export function HomeHotspot({ node, hoverBlock, data }) {
  /*
   * properties
   */

  const [hovered, setHovered] = useState()
  useCursor(hovered /*'pointer', 'auto', document.body*/)

  const group = useRef()
  const element = useRef()

  const { startTransitionOut, transition } = useTransit()
  const { activeSite, sites, setActiveSite, unsetActiveSite } = useOverview()
  const { isMobile } = useSize()

  const sanityHotspots = useHotspot((state) => state.sanityHotspots)

  const industry = sanityHotspots.industries.find(
    (hotspot) => hotspot.uid === data.id,
  )
  const visible = industry.visibility

  /*
   * hooks
   */

  // set position when the mesh has been loaded
  useEffect(() => {
    // set position of the parent group to the position of the source mesh

    hoverBlock.material.transparent = true
    hoverBlock.material.opacity = 0

    group.current.position.copy(node.position)
  }, [node, hoverBlock])

  useEffect(() => {
    const opacity = activeSite && activeSite.id === data.id ? 1 : 0

    gsap.killTweensOf(hoverBlock.material)
    gsap.to(hoverBlock.material, {
      opacity: opacity,
      duration: 0.5,
      ease: "sine.out",
    })
  }, [activeSite, data])

  /*
   * interaction methods
   */

  const onClick = () => {
    //console.log('SiteHotspot :: clickHandler');
    //console.log('data.id: ' + data.id);

    startTransitionOut(data.route)
  }

  const onOver = () => {
    if (!useSize.getState().isMobile) {
      setHovered(true)
      setActiveSite(data)
    }
  }

  const onOut = () => {
    // console.log('onOut');
    if (!useSize.getState().isMobile) {
      setHovered(false)
      unsetActiveSite()
    }
  }

  /*
   * visuals
   */

  return (
    <>
      <group ref={group} onPointerOver={onOver} onPointerOut={onOut}>
        {/* <Html ref={element} zIndexRange={[1000, 0]}>
          <button disabled={!(activeSite && activeSite.id === data.id)} onClick={onClick} className={style.button}>
            {t(`sections.${data.id}`)}
          </button>
        </Html> */}
        {visible && (
          <mesh
            position={[0, data.id === "business" ? -0.5 : -0.1, 0]}
            onClick={onClick}
          >
            <boxGeometry args={[1, 0.1, 1]} />
            <MeshDiscardMaterial />
          </mesh>
        )}
      </group>
    </>
  )
}

import style from "./VideoPanel.module.css"
import { VideoPlayer } from "../../../components/VideoPlayer"

export function VideoPanel({ data }) {
  /*
   * visuals
   */

  return (
    <div className={style.container}>
      <div className={style.videoWrapper}>
        <VideoPlayer poster={data.poster} src={data.video?.videoUrl} />
        {/* <VideoPlayer poster={data.poster} src=".//video/samples/bunny.mp4" /> */}
      </div>
      <div className={style.content}>
        <div>
          <h3 className={style.title}>{data.title}</h3>
          <p className={style.body}>{data.subtitle}</p>
        </div>
        <div>
          <p className={style.body}>{data.length}</p>
        </div>
      </div>
    </div>
  )
}

import { useEffect, useMemo, useRef, useState } from "react"
import style from "./VideoPlayer.module.css"
import { RoundButton } from "./RoundButton"
import { getPosterImage } from "../../utils/sanityImages"


export function VideoPlayer({ poster, src }) {
  /*
   * properties
   */

  const video = useRef()
  const container = useRef()
  const [isPlaying, setPlaying] = useState(false)
  

  /*
   * hooks
   */


  const posterUrl = useMemo(() => {
    // console.log('VideoPlayer :: posterUrl');
    // console.log(poster)
    if (poster) {
      return getPosterImage(poster)
    }
    return poster
  }, [poster])

  useEffect(() => {
    window.addEventListener("click", globalClickHandler)

    return () => {
      window.removeEventListener("click", globalClickHandler)
    }
  }, [])

  function playHandler() {
    // console.log('playHandler');
    setPlaying(true)
  }

  function pauseHandler() {
    // console.log('pausHandler');
    setPlaying(false)
  }

  const toggle = () => {
    //console.log(video.current);
    if (isPlaying) {
      // console.log('toggle - pause');
      video.current.pause()
    } else {
      // console.log('toggle - play');
      video.current.play()
    }
  }

  const fullscreenHandler = () => {
    const vid = video.current
    if (vid.requestFullscreen) {
      vid.requestFullscreen()
    } else if (vid.webkitRequestFullscreen) {
      /* Safari */
      vid.webkitRequestFullscreen()
    } else if (vid.msRequestFullscreen) {
      /* IE11 */
      vid.msRequestFullscreen()
    }
  }

  const globalClickHandler = (e) => {
    // console.log('globalClickHandler');
    // console.log(e.target);

    if (!container.current.contains(e.target)) {
      video.current.pause()
    }
  }

  /*
   * visuals
   */

  return (
    <div className={style.container} ref={container}>
      <video
        ref={video}
        onPlay={playHandler}
        onPause={pauseHandler}
        className={style.video}
        playsInline
        src={src}
        poster={posterUrl}
        // preload="metadata"
      />
        {/* <source type="/video/mp4" /> */}
      {/* </video> */}
      <div className={style.playButtonWrapper}>
        <RoundButton
          onClick={toggle}
          icon={isPlaying ? "close" : "play"}
          color="primary"
        />
      </div>
      <div className={style.fullscreenButtonWrapper}>
        <RoundButton
          onClick={fullscreenHandler}
          icon="fullscreen"
          color="white"
        />
      </div>
    </div>
  )
}

import { Site } from "./Site"
import * as THREE from "three"
import { useTexture, useVideoTexture } from "@react-three/drei"

export function Construction({ ktx2Loader, dracoLoader }) {
  /*
   * properties
   */

  // textures and videos
  const career_stories_construction = useTexture(
    "/textures/Career-stories-construction.jpg",
  )
  const industry_intros_construction = useTexture(
    "/textures/Industry-intros-construction.jpg",
  )
  // const test_screen_bars = useTexture("/textures/test_screen_bars.jpg")

  const uv_test = useTexture("/textures/uv_test.jpg")

  // link textures and/or video's to model nodes
  const screenTextures = {
    // Screen_Info_Point_Lvl01: uv_test,
    // Screen_Lvl01: uv_test,
    Screen_Info_Point_Lvl01: industry_intros_construction,
    Screen_Lvl01: career_stories_construction,
  }

  /*
   * init: run Site specific code after the modal has been loaded
   */

  const init = (model, animations) => {
    // console.log('Construction :: init');

    // console.log('model');
    // console.log(model);
    // console.log('model.nodes.Ground.position');
    // console.log(model.nodes.Ground.position);
    // console.log('model.nodes.Exterior_Strokes.position PRE');
    // console.log(model.nodes.Exterior_Strokes.position);

    // model.nodes.Exterior_Strokes.position.y = -.1;

    // console.log('model.nodes.Exterior_Strokes.position POST');
    // console.log(model.nodes.Exterior_Strokes.position);

    // model.nodes.Exterior_Strokes.visible = false;

    // console.log('animations');
    // console.log(animations);

    const action = animations.actions["Animation"]
    action.play()
  }

  /*
   * visuals
   */

  return (
    <group>
      <Site
        name="construction"
        cameraOrder={[0, 1, 2, 3]}
        cameraScale={0.2}
        focusDistance={0.04}
        focusLength={0.04}
        init={init}
        // url={'/models/construction/04_Construction_001.glb'}
        // url={'/models/construction/resize.glb'}

        url={"/models/construction/resize-ktx-ETC1S-draco.glb"}
        // url={"/models/construction/04_Construction_010_Screen_UVs_Updated.glb"}

        // url={'/models/construction/resize-ktx-ETC1S.glb'}
        screenTextures={screenTextures}
        ktx2Loader={ktx2Loader}
        dracoLoader={dracoLoader}
      />
    </group>
  )
}

import { useEffect, useMemo } from "react"
import style from "./ModalMenu.module.css"
import { ModalMenuButton } from "./ModalMenuButton"
import { ModalMenuTitle } from "./ModalMenuTitle"
import { useSite } from "../../../stores/useSite"
import { useHotspot } from "../../../stores/useHotspot"

export function ModalMenu() {
  /*
   * properties
   */
  const { site } = useSite()
  const { getHotspots } = useHotspot()

  /*
   * hooks
   */

  const hotspots = useMemo(() => {
    const hotspots = getHotspots(site)
    return hotspots ? hotspots : []
  }, [site])

  // console.log(hotspots)

  /*
   * interactivity methods
   */

  /*
   * visuals
   */
  return (
    <>
      <nav className={style.nav}>
        <ModalMenuTitle label="Level 1" spaced={false} />
        {hotspots
          .filter((x) => x.level === 1)
          .map((hotspot, index) =>
            !hotspot.disabled &&
            hotspot.hotspotType &&
            hotspot.hotspotType[0]._type !== "empty" ? (
              <ModalMenuButton key={index} hotspot={hotspot} />
            ) : null,
          )}
        <ModalMenuTitle label="Level 2" spaced={true} />
        {hotspots
          .filter((x) => x.level === 2)
          .map((hotspot, index) =>
            !hotspot.disabled &&
            hotspot.hotspotType &&
            hotspot.hotspotType[0]._type !== "empty" ? (
              <ModalMenuButton key={index} hotspot={hotspot} />
            ) : null,
          )}
        <ModalMenuTitle label="Level 3" spaced={true} />
        {hotspots
          .filter((x) => x.level === 3)
          .map((hotspot, index) =>
            !hotspot.disabled &&
            hotspot.hotspotType &&
            hotspot.hotspotType[0]._type !== "empty" ? (
              <ModalMenuButton key={index} hotspot={hotspot} />
            ) : null,
          )}
      </nav>
    </>
  )
}

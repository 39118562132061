import style from "./LanguageMenu.module.css"
import { getLocaledPath, getCurrentLocale } from "../../utils/localization"
import { SHORTHAND_LOCALES, SUPPORTED_LOCALES } from "../../../config"
import { useResolvedPath } from "react-router-dom"
import { useSettings } from "../../stores/useSettings"

export function LanguageMenu() {
  const { pathname } = useResolvedPath()
  const { currentLocale } = getCurrentLocale(pathname)
  const settings = useSettings((state) => state.settings)
  const visibleLocales =
    settings?.translation?.visibleLocales || SHORTHAND_LOCALES

  return (
    <div className={style.languageMenu}>
      {SUPPORTED_LOCALES.filter((x) => visibleLocales.includes(x.code)).map(
        (locale) => {
          return (
            <a
              key={locale.code}
              href={getLocaledPath({ path: pathname, locale: locale.code })}
              className={`${style.button} ${
                currentLocale === locale.code ? style.selected : ""
              }`}
              aria-label={locale.title}
            >
              <img
                src={`/images/ui/${locale.code}.png`}
                alt={locale.title + " Flag"}
              />
            </a>
          )
        },
      )}
    </div>
  )
}

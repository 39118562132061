import { Icon } from './Icon';
import style from './RoundButton.module.css';

export function RoundButton({ color, icon, onClick }) {
  return (
    <button className={`${style.button} ${style[color]}`} onClick={onClick}>
      <div className={style.icon}>
        <Icon svg={icon} light={color === 'transparent' || color === 'minimal'} size="small" />
      </div>
    </button>
  );
}

import { Icon } from "../components/Icon"
import style from "./Mobile.module.css"

export function Mobile() {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */
  return (
    <>
      <div className={style.container}>
        <div className={style.block}>
          <h1>How to guide</h1>
          <p className={style.large}>
            If you are unsure how to navigate this website - this quick guide is
            here to help!
          </p>
          <p className={style.large}>
            Once you have chosen you industry from the main map you will see
            this menu at the bottom of your screen. From here you can navigate
            through each level and choose the activity you want to participate
            in.
          </p>
        </div>
        <div className={style.columns}>
          <div className={style.menuButton}>
            <Icon svg="hamburger" size="small" />
          </div>
          <div className={style.block}>
            <h2>Menu</h2>
            <p>
              When you click this - it will show all the levels and the
              different content types in each level. This is you main navigation
              menu when inside one of the industry zones.
            </p>
          </div>
        </div>
        <div className={style.block}>
          <div className={style.columns}>
            <div className={style.block}>
              <h2>Levels</h2>
              <p>
                Each industry zone has 3 levels that are placed in different
                areas of the selected zone. You can tap the current level to
                switch levels and the camera will move automatically.
              </p>
            </div>
            <div className={style.block}>
              <h2>Hotspots</h2>
              <p>
                In each level you will find different hotspots that will be
                hovering over certain elements in each level. You can also
                choose the hotspots from this app style menu too.
              </p>
            </div>
          </div>
          <div className={style.levelMenu}>
            <div className={style.arrowLeft} />
            <div className={style.levelMenuItem}>LEVEL 3</div>
            <div className={style.arrowDown} />
          </div>
          <div className={style.hotspotMenu}>
            <div className={style.hotspotMenuItem}>
              <Icon svg="industryIntroduction" size="small" />
              Industry
              <br />
              Introduction
            </div>
            <div className={`${style.hotspotMenuItem} ${style.highlight}`}>
              <Icon svg="collegePrograms" size="small" />
              College
              <br />
              Programs
            </div>
            <div className={style.hotspotMenuItem}>
              <Icon svg="workExperienceSprints" size="small" />
              Experience
              <br />
              Sprints
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

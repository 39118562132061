import { create } from 'zustand';

export const useMouse = create((set, get) => ({
  x: 0, // the cursor's x position in pixels
  y: 0, // the cursor's y position in pixels
  slowX: 0, // the cursor's tweened x position in pixels
  slowY: 0, // the cursor's tweened y position in pixels
  pctX: 0, // the cursor's x position as a percentage from -1 to 1
  pctY: 0, // the cursor's y position as a percentage from -1 to 1
  slowPctX: 0, // the cursor's tweened x position as a percentage from -1 to 1
  slowPctY: 0, // the cursor's tweened y position as a percentage from -1 to 1

  xMax: 0, // the maximum possible value of the cursor's x position in pixels
  yMax: 0, // the maximum possible value of the cursor's y position in pixels

  inited: false, // true if the user has moved the cursor at least once

  // set inited to true after the user has moved the cursor at least once
  initMousePosition: () => set({ inited: true }),

  // set the x and y position of the cursor (in pixels)
  setMousePosition: (x, y) => set({ x, y, pctX: -1 + (x / get().xMax) * 2, pctY: -1 + (y / get().yMax) * 2 }),

  // set the maximum possible value of the cursor's x and y position (in pixels)
  setMouseMaximums: (xMax, yMax) =>
    set({
      xMax,
      yMax,
      x: Math.min(get().x, xMax),
      y: Math.min(get().y, yMax),
      slowX: Math.min(get().slowX, xMax),
      slowY: Math.min(get().slowY, yMax),
    }),

  // calculate all the tweened properties
  calculateSlowMousePosition: () =>
    set({
      slowX: get().slowX + (get().x - get().slowX) * 0.1,
      slowY: get().slowY + (get().y - get().slowY) * 0.1,
      slowPctX: -1 + (get().slowX / get().xMax) * 2,
      slowPctY: -1 + (get().slowY / get().yMax) * 2,
    }),
}));

import { useHotspot } from "../../../stores/useHotspot"
import { Icon } from "../../components/Icon"
import style from "./ModalMenuButton.module.css"

export function ModalMenuButton({ hotspot }) {
  /*
   * properties
   */

  const { setActiveHotspot, activeHotspot } = useHotspot()

  /*
   * hooks
   */

  /*
   * interaction methods
   */

  const clickHandler = () => {
    setActiveHotspot(hotspot.id)
  }

  /*
   * visuals
   */
  return (
    <>
      <button
        className={`${style.button} ${
          activeHotspot === hotspot.id ? style.selected : ""
        } ${hotspot.disabled && style.disabled}`}
        onClick={clickHandler}
      >
        <div className={style.mainIcon}>
          <Icon svg={hotspot.hotspotType[0]._type} size="small" />
        </div>

        {/* {t(`hotspots.${site}.${hotspot.id}`)} */}
        {hotspot.name}

        <div className={style.arrowIcon}>
          <Icon svg="arrow-next" size="small" />
        </div>
      </button>
    </>
  )
}

import { useEffect, useRef } from "react"
import style from "./SpotlightPanel.module.css"
import { VideoPlayer } from "../../../components/VideoPlayer"
import { getLogo } from "../../../../utils/sanityImages"
import { Icon } from "../../../components/Icon"
import Localize from "../../../components/Localize"

export function SpotlightPanel({ data }) {
  /*
   * properties
   */

  const container = useRef()

  useEffect(() => {
    console.log("SpotlightPanel :: data")
    console.log(data)
  }, [data])

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <div className={style.container} ref={container}>
      <div className={style.videoWrapper}>
        <VideoPlayer poster={data.thumbnail} src={data.trailerLink} />
        {data.tags && data.tags.length > 0 && (
          <div className={style.spotlight}>{data.tags[0].toUpperCase()}</div>
        )}
        <img
          className={style.logo}
          alt={data.logo.alt}
          src={getLogo(data.logo)}
        />
      </div>
      <div className={style.content}>
        <h3 className={style.title}>{data.title}</h3>
        <p className={style.body}>{data.intro}</p>
        <a href={data.courseLink} target="_blank" className={style.button}>
          {/* <span>{t("modal.employabilityCta")}</span> */}
          <Localize label="employability.cta" />
          <div className={style.icon}>
            <Icon svg="arrow-next" size="small" />
          </div>
        </a>
      </div>
    </div>
  )
}

import PicoSanity from "picosanity"
import { resolveLinks } from "./resolveLinks"
import { walkAsync } from 'walkjs'
import { DEFAULT_LOCALE } from "../../config"

export const client = new PicoSanity({
  apiVersion: "v2023-04-20",
  projectId: "2v0lz17v",
  perspective: 'published',
  dataset: "production",
  token: import.meta.env.VITE_SANITY_KEY,
  // token: impo,
  // useCdn: !import.meta.env.DEV, // Only use CDN in production or data will not update properly with HMR
  useCdn: import.meta.env.PROD
})

// const builder = ImageUrlBuilder(client)

// export const urlFor = (source) => { 
//   return builder.image(source)
// }


export const getIndustries = async () => {
  // Global labels of instrues as another object

  const result = await client.fetch(/* groq */ `{'industries': *[_type == 'industry']}`).then(async (data) => {
    await resolveLinks(data)
    return data
  })

  return result
}

export const getIndustriesList = async () => {
  return await client.fetch(
    /* groq */ `*[_type == 'industry']{uid,industryName}`,
  )
}

export const getSettings = async () => {
  const result = client
    .fetch(/* groq */ `*[_id in path("settings.**")]`)
    .then(async (res) => {
      const settings = {}
      res.forEach((setting) => {
        const name = setting._type.replace("settings.", "")
        const o = { [name]: setting }
        Object.assign(settings, o)
      })
      return settings
    })
  return result
}

export const localize = async (data, locale = DEFAULT_LOCALE.code) => {

  const i18Fields = ['i18n.string', 'i18n.text', 'i18n.number', 'hotspotTitle', 'bodyCopyShort', 'bodyCopy']
  const richi18TextFields = ['emphasizedHeading', 'normalText', 'blockContent']

  await walkAsync(data, {
    onVisit: {
      callback: async (node) => {
        const isI18nField = new RegExp(i18Fields.join('|')).test(
          node.parent?.val?._type,
        )
        const isRichText = new RegExp(richi18TextFields.join('|')).test(
          node.parent?.val?._type,
        )

        const isLink = node.val?._type === 'slug' && node?.val?.current


        if (isI18nField || isRichText) {
          let localizedValue

          // Use default locale if the locale is not available OR isnt filled in
          if (node.parent.val[locale] || node.parent.val[locale] === '') {
            localizedValue = node.parent.val[locale]
          } else {
            localizedValue = node.parent.val[DEFAULT_LOCALE.code]
          }

          node.parent.parent.val[node.parent.key] = localizedValue
          node.parent.val = localizedValue

          // console.log('localizedValue:', node.parent.val)
        }
        // else if (isLink) {
        //   const slugObject = node.parent.val.slug
        //   let slug = slugObject?.fullUrl || slugObject?.current || slugObject
        //   let localizedSlug = ''


        //   const localeSegment =
        //     locale === DEFAULT_LOCALE.code ? '' : `/${locale}`

        //   // console.log('for locale w/ seg:', localeSegment)

        //   // if (slug[0] !== '/') {
        //   //   slug = `/${slug}`
        //   // }

        //   // slug = `${localeSegment}`
        //   // console.log('localeseg', localeSegment)

        //   // localizedSlug[locale] = `/${localeSegment}/${slug}`

        //   localizedSlug = `${localeSegment}/${slug}`.replace('//', '/')

        //   // get last part of the lsug
        //   const lastUrlSegment = slug.split('/').pop()

        //   // console.log('last seg', lastUrlSegment)

        //   node.parent.val.slug = localizedSlug.startsWith('/')
        //     ? localizedSlug
        //     : `/${localizedSlug}`
        //   node.parent.val.url = lastUrlSegment

        //   // console.log('----- FINAL SLUG:', node.parent.val.slug)
        // }
      },
    },
  })

  return data
}
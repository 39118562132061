import { Icon } from "../components/Icon"
import style from "./Desktop.module.css"

export function Desktop() {
  /*
   * properties
   */

  /*
   * hooks
   */

  /*
   * visuals
   */

  return (
    <>
      <div className={style.container}>
        <div className={style.block}>
          <h1>How to guide</h1>
          <p className={style.large}>
            If you are unsure how to navigate this website - this quick guide is
            here to help!
          </p>
          <p className={style.large}>
            Once you have chosen you industry from the main map you will see
            this menu at the bottom of your screen. From here you can navigate
            through each level and choose the activity you want to participate
            in.
          </p>
        </div>
        <div>
          <div className={style.bottom}>
            <div className={style.columns}>
              <div className={style.block}>
                <h2>Menu</h2>
                <p>
                  When you click this - it will show all the levels and the
                  different content types in each level. This is you main
                  navigation menu when inside one of the industry zones.
                </p>
                <div className={style.arrow} />
              </div>
              <div className={style.block}>
                <h2>Levels</h2>
                <p>
                  Each industry zone has 3 levels that are placed in different
                  areas of the selected zone. You can cycle through them and the
                  camera will take you to each level.
                </p>
                <div className={style.arrow} />
              </div>
            </div>
            <div className={style.columns}>
              <div className={style.hotspot}>
                <div className={style.icon}>
                  <Icon svg="dotline" size="responsive" />
                </div>
                <div className={style.ring}></div>
              </div>
              <div className={style.block}>
                <h2>Hotspots</h2>
                <p>
                  In each level you will find different hotspots that will be
                  hovering over certain elements. The marker to the right will
                  highlight where they are. You can also choose the hotspots
                  from this menu too.
                </p>
                <div className={style.arrow} />
              </div>
            </div>
          </div>
          <div className={style.bottom}>
            <div className={style.columns}>
              <div className={style.menu}>
                <Icon svg="hamburger" />
                <div className={style.menuTitle}>MENU</div>
              </div>

              <div className={style.menu}>
                <div className={`${style.menuTitle} ${style.responsive}`}>
                  LEVEL
                </div>
                <div className={style.roundButton}>1</div>
                <div className={`${style.roundButton} ${style.highlight}`}>
                  2
                </div>
                <div className={style.roundButton}>3</div>
              </div>
            </div>

            <div className={style.menu}>
              <div className={`${style.menuTitle} ${style.responsive}`}>
                HOTSPOT
              </div>
              <div className={style.bigButton}>
                <Icon svg="industryIntroduction" size="small" /> Industry
                Introduction
              </div>
              <div className={`${style.bigButton} ${style.highlight}`}>
                <Icon svg="collegePrograms" size="small" /> College Programs
              </div>
              <div className={style.bigButton}>
                <Icon svg="workExperienceSprints" size="small" /> Experience
                Sprints
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
